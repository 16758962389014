import React from 'react';
import TitleStandard from '../Commons/TitleStandard';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import frecciaDX from '../../assets/customIcons/freccia_dx_scheda.svg';
import { Link } from 'react-router-dom';
import { OSMMap } from 'volto-venue';
import RestaurantInfoSection from './Commons/Restaurants/RestaurantInfoSection';
import PrintPDF from './Commons/PrintPDF';

const messages = defineMessages({
  antipasti: {
    id: 'antipasti',
    defaultMessage: 'Antipasti',
  },
  primo: {
    id: 'primo',
    defaultMessage: 'Primi piatti',
  },
  secondo: {
    id: 'secondo',
    defaultMessage: 'Secondi piatti',
  },
  dessert: {
    id: 'dessert',
    defaultMessage: 'Dessert',
  },
  menu_unico: {
    id: 'menu_unico',
    defaultMessage: 'Menu unico',
  },
});

const MenuView = ({ content }) => {
  const intl = useIntl();
  return (
    <>
      <TitleStandard title={content.title} />

      <div className="menu-view ui container">
        <PrintPDF item={content} />
        <div className="super-padding">
          {/*  ANTIPASTO */}
          {content.antipasto?.data.replace(/(<([^>]+)>)/g, '').length > 0 && (
            <div className="mt-5 text-center">
              <h2>{intl.formatMessage(messages.antipasti)}</h2>
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: content.antipasto?.data }}
                />
              </div>
            </div>
          )}

          {/*  PRIMI PIATTI */}
          {content.primo?.data.replace(/(<([^>]+)>)/g, '').length > 0 && (
            <div className="mt-3 text-center">
              <h2>{intl.formatMessage(messages.primo)}</h2>
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: content.primo?.data }}
                />
              </div>
            </div>
          )}

          {/*  SECONDI PIATTI */}
          {content.secondo?.data.replace(/(<([^>]+)>)/g, '').length > 0 && (
            <div className="mt-3 text-center">
              <h2>{intl.formatMessage(messages.secondo)}</h2>
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: content.secondo?.data }}
                />
              </div>
            </div>
          )}

          {/*  Dessert */}
          {content.dessert?.data.replace(/(<([^>]+)>)/g, '').length > 0 && (
            <div className="mt-3 text-center">
              <h2>{intl.formatMessage(messages.dessert)}</h2>
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: content.dessert?.data }}
                />
              </div>
            </div>
          )}

          {/*  MENU UNICO */}
          {content.menu_unico?.data.replace(/(<([^>]+)>)/g, '').length > 0 && (
            <div className="mt-3 text-center">
              {content?.tipologia_menu_unico?.length === 0 && (
                <h2>{intl.formatMessage(messages.menu_unico)}</h2>
              )}
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.menu_unico?.data,
                  }}
                />
              </div>
            </div>
          )}
        </div>

        {/*  PREZZO */}
        <div className="mt-5 text-center menu_unico">
          <div className="px-1">
            <h4>{content.prezzo}€</h4>
            <div className="note">
              <span>Note</span>
              <div dangerouslySetInnerHTML={{ __html: content.note?.data }} />
            </div>
          </div>
        </div>

        {/*  INFO RISTORANTE */}
        <div className="text-center ristorante">
          <div className="px-2">
            <div className="locationInfo">lo trovi presso</div>
            <div className="title">{content.ristorante?.title}</div>
            <div>{content.ristorante?.tipologia_ristorante?.title}</div>
            <Link
              to={
                content.ristorante
                  ? flattenToAppURL(content.ristorante['@id'])
                  : null
              }
            >
              <Image className="mt-05" src={frecciaDX} centered></Image>
            </Link>
          </div>
          <RestaurantInfoSection content={content.ristorante} />
        </div>

        {/* Mappa */}
        {__CLIENT__ && content.ristorante?.geolocation && (
          <div className="fullwidth-map">
            <OSMMap
              markers={[
                {
                  latitude: content.ristorante?.geolocation?.latitude || 0,
                  longitude: content.ristorante?.geolocation?.longitude || 0,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MenuView;
