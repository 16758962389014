import React from 'react';

const BodyWrapper = ({ children }) => {
  return (
    <div>
      <div className="flex flex-wrap justify-center">{children}</div>
    </div>
  );
};
export default BodyWrapper;
