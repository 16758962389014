import React from 'react';
import Sponsor from './Sponsor';

const Block = ({ data, isEditMode }) => {
  return (
    <div className="sponsors-container mb-2">
      <div className="text text-center">{data.description}</div>
      <div className="flex direction-column mt-1">
        {data.sponsors?.map((s, index) => {
          return <Sponsor sponsor={s} isEditMode={isEditMode} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Block;
