import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import CommonImage from '../Image';
import PropTypes from 'prop-types';
import Attachments from '../Attachments';
import moment from 'moment';
import { rrulestr } from 'rrule';
import { defineMessages, useIntl } from 'react-intl';
import altriAppuntamenti from '../../../../assets/eventsImage/altri_appuntamenti.jpg';
import degustazione from '../../../../assets/eventsImage/degustazione.jpg';
import incontri_spettacoli from '../../../../assets/eventsImage/incontri_spettacoli.jpg';
import laboratori from '../../../../assets/eventsImage/laboratori.jpg';
import mercati from '../../../../assets/eventsImage/mercati.jpg';
import mostre from '../../../../assets/eventsImage/mostre.jpg';
import per_bambini from '../../../../assets/eventsImage/per_bambini.jpg';
import visite_guidate from '../../../../assets/eventsImage/visite_guidate.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getContent, resetContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  date: {
    id: 'date',
    defaultMessage: 'Data',
  },
  time: {
    id: 'time',
    defaultMessage: 'Orario',
  },
  location: {
    id: 'location',
    defaultMessage: 'Luogo',
  },
  start: {
    id: 'start',
    defaultMessage: 'da',
  },
  end: {
    id: 'end',
    defaultMessage: 'a',
  },
  event_hours_in_description: {
    id: 'event_hours_in_description',
    defaultMessage: 'Vedi la descrizione',
  },
});

/**
 * EventInfoSection view component class.
 * @function EventInfoSection
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const EventInfoSection = ({ content }) => {
  const intl = useIntl();
  moment.locale('it');

  const searchResults = useSelector((state) => state.content?.subrequests);
  const dispatch = useDispatch();

  const displayDates = () => {
    /* Render dates if the event is recurrent */
    if (dates?.length > 0)
      return dates.map((date) => (
        <>
          <br />
          {moment(date).format('dddd D MMMM')}
        </>
      ));

    if (
      !moment(content.start)
        .startOf('day')
        .isSame(moment(content.end).startOf('day'))
    ) {
      return `${intl.formatMessage(messages.start)} ${moment(
        content.start,
      ).format('dddd D MMMM')} ${intl.formatMessage(messages.end)} ${moment(
        content.end,
      ).format('dddd D MMMM')}`;
    } else return moment(content?.start).format('dddd D MMMM');
  };

  const displayHours = () => {
    if (content.whole_day)
      return intl.formatMessage(messages.event_hours_in_description);
    if (!content.open_end)
      return `dalle ${moment(content.start).format('HH:mm')} alle ${moment(
        content.end,
      ).format('HH:mm')}`;
    else return `dalle ${moment(content.start).format('HH:mm')}`;
  };

  const dates = content.recurrence
    ? rrulestr(content.recurrence, {
        compatible: true,
        forceset: true,
      }).all()
    : [];

  // one request is made for every 'unita_amministrativa_responsabile' selected
  useEffect(() => {
    if (content.location) {
      content.location.forEach((x) => {
        dispatch(
          getContent(flattenToAppURL(x['@id']), null, x['@id'], null, true),
        );
      });
    }
    return () => {
      if (content.location) {
        content.location.forEach((x) => {
          dispatch(resetContent(x['@id']));
        });
      }
    };
  }, [dispatch, content]);
  const getDefaultImage = (type) => {
    switch (type) {
      case 'incontri-e-spettacoli':
        return incontri_spettacoli;
      case 'degustazioni-e-cene':
        return degustazione;
      case 'laboratori-e-show-cooking':
        return laboratori;
      case 'mercati':
        return mercati;
      case 'mostre':
        return mostre;
      case 'per-bambini':
        return per_bambini;
      case 'visite-guidate':
        return visite_guidate;
      case 'altri-appuntamenti':
        return altriAppuntamenti;
      default:
        return undefined;
    }
  };
  return (
    <div className={'info-section flex'}>
      <Grid
        className={'centered w-100'}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid.Column
          mobile={12}
          tablet={10}
          computer={8}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <CommonImage
            container_class={'event-image-container'}
            image_class={'fit-image object-fit_cover'}
            image={
              content.image?.download ||
              getDefaultImage(content.tipologia_evento[0]?.token)
            }
            caption={null}
          />
        </Grid.Column>
        <Grid.Column
          className={'information-container'}
          mobile={12}
          tablet={10}
          computer={4}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={'information'}>
            <p>
              <span>{`${intl.formatMessage(messages.date)}:`}</span>
              {displayDates()}
            </p>
            <p>
              <span>{`${intl.formatMessage(messages.time)}:`}</span>
              {displayHours()}
            </p>
            <p>
              {content.location?.length > 0 && (
                <span>{`${intl.formatMessage(messages.location)}:`}</span>
              )}

              {content.location?.length > 0
                ? content.location?.map((loc, i) => {
                    return `${loc.title}
                    ${
                      searchResults[loc['@id']]?.data?.street
                        ? ' - ' + searchResults[loc['@id']]?.data?.street
                        : ''
                    }
                    ${
                      searchResults[loc['@id']]?.data?.zip_code
                        ? ', ' + searchResults[loc['@id']]?.data?.zip_code
                        : ''
                    }
                    ${
                      searchResults[loc['@id']]?.data?.city
                        ? ', ' + searchResults[loc['@id']]?.data?.city
                        : ''
                    }
                    ${i < content.location.length - 1 ? ', ' : ''}`;
                  })
                : null}
            </p>

            {content?.items?.map(function (folder) {
              let folder_name = folder['@id'].split('/').pop();
              return (
                <Attachments
                  key={folder_name}
                  folder_url={folder['@id']}
                  folder_name={folder_name}
                  title={folder.title}
                />
              );
            })}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default EventInfoSection;

EventInfoSection.propTypes = {
  content: PropTypes.shape({
    '@id': PropTypes.string,
    '@type': PropTypes.string,
    image: PropTypes.any,
    location: PropTypes.any,
    items: PropTypes.any,
  }),
};
