/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Segment, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import LogoImage from './logo.svg';
import fbIcon from './fb_icon.svg';
import instaIcon from './insta_icon.svg';
import searchIcon from './search_icon.svg';
import burgerIcon from './burger.svg';
import { Link } from 'react-router-dom';
import { Sidenav } from './sidenav';
import OutsideClickHandler from 'react-outside-click-handler';
import { getHeaderDates } from '../../../../actions';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
export const Header = ({ pathname }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const headerDates = useSelector((state) => state.getHeaderDates.header_dates);
  useEffect(() => {
    dispatch(getHeaderDates());
    return () => {};
  }, [dispatch]);

  return (
    <div className="header">
      <div className="primary-bg top-menu w-100 header-wrapper only-pc">
        <div className="container h-100">
          <div className="top-header h-100 pb-05">
            <div className="flex">
              <a href="https://www.facebook.com/imolabaccanale">
                <Image className="icon mr-1" src={fbIcon} />
              </a>
              <a href="https://www.instagram.com/baccanaleimola/?hl=it">
                <Image className="icon" src={instaIcon} />
              </a>
            </div>
            <Link to="/hai-una-iniziativa-da-proporre">
              Hai una iniziativa da proporre?
            </Link>
            <Link to="/sei-un-ristoratore">Sei un ristoratore?</Link>
            <Link to="/area-stampa">Area stampa</Link>
            <Link to="/contattaci">Contattaci</Link>
            <Link to="/search">
              <Image className="icon" src={searchIcon} />
            </Link>
          </div>
        </div>
      </div>
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="container">
            <div className="flex justify-center align-center relative mb-2 center-menu">
              <div
                className="until-tablet"
                onClick={() => {
                  setIsOpen(true);
                }}
                role="button"
              >
                <Image className="mobile-icon left" src={burgerIcon} />
              </div>
              <Link to="/">
                <div className="flex justify-center">
                  <Image className="logo" src={LogoImage} />
                </div>
              </Link>
              <div className="until-tablet">
                <Link to="/search">
                  <Image className="mobile-icon right" src={searchIcon} />
                </Link>
              </div>
              <div className="imola-dintorni only-pc">
                <div>IMOLA e dintorni</div>
                <div>{headerDates}</div>
              </div>
            </div>
            <div className="only-pc primary-menu">
              <Link to="/il-baccanale">Il Baccanale</Link>
              <Link to="/programma">Programma</Link>
              <Link to="/non-solo-news">Non solo news</Link>
              <Link to="/i-menu-del-baccanale">I menu del Baccanale</Link>
              <Link to="/fuorimenu">Fuorimenu</Link>
            </div>
          </div>
        </Container>
      </Segment>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <Sidenav isOpen={isOpen} close={() => setIsOpen(false)}></Sidenav>
      </OutsideClickHandler>
    </div>
  );
};

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);
