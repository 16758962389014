/**
 * PageView view component.
 * @module components/theme/View/PageView
 */

import React, { useEffect, useState } from 'react';
import { Input, Pagination, Loader } from 'semantic-ui-react';
import TitleStandard from '../Commons/TitleStandard';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVocabulary,
  resetContent,
  searchContent,
} from '@plone/volto/actions';
import { Accordion, Icon, Form, Dimmer } from 'semantic-ui-react';
import ListingEvents from './Commons/ListingEvents';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { rrulestr } from 'rrule';

/**
 * PageView view component class.
 * @function PageView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

// const getOccurrence = (originalDate, occurrenceDate) => {
//   if (!originalDate) {
//     return originalDate;
//   }
//   const original = moment.utc(originalDate);
//   const occurrence = moment.utc(occurrenceDate);
//   occurrence.hour(original.hour());
//   occurrence.minute(original.minute());
//   return occurrence;
// };

var getDaysBetweenDates = (evento, startDate, endDate) => {
  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.clone());
    now.add(1, 'days');
  }
  return dates;
};

const RicercaProgrammaView = ({ content, location }) => {
  const [filteredEventi, setFilteredEventi] = useState([]);
  const [filteredEventiPaged, setFilteredEventiPaged] = useState([]);
  const [filter, setFilter] = useState({});

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const filters = state?.vocabularies;
  const eventi = state?.search?.subrequests?.eventi?.items;
  const eventiRequest = state?.search?.subrequests?.eventi;

  let occurrences = eventi;

  let offset = 0;
  const MAX_ITEMS = 9;

  filters['luoghi'] = eventi
    ?.reduce((total, evento) => {
      const luogo = evento.location[0]?.title;
      if (luogo == null) return total;

      if (!total.includes(luogo)) total.push(luogo);
      return total;
    }, [])
    .sort();

  // Evento che scade a gennaio non è nel calendario: riproduci filtrando per l'ultima data
  // minor: se creo un evento non si vede fra i primi anche se dovrebbe
  filters['dates'] = eventi
    ?.reduce((total, evento) => {
      const data = moment(evento.start);
      if (data == null) {
        return total;
      }
      total.push(data);

      // An event must be filterable by his start date
      evento.filterable_dates = [data.locale('it')?.format('DD MMMM')];

      if (evento.recurrence) {
        const rruleSet = rrulestr(evento.recurrence, {
          compatible: true, //If set to True, the parser will operate in RFC-compatible mode. Right now it means that unfold will be turned on, and if a DTSTART is found, it will be considered the first recurrence instance, as documented in the RFC.
          forceset: true,
        });
        let dates = rruleSet.all();
        evento.r_dates = [];
        dates.forEach((d) => {
          let r_data = moment(d);
          total.push(r_data);
          // An event should be filterable by all his occurrences
          evento.filterable_dates.push(r_data.locale('it')?.format('DD MMMM')); // Used by filters
        });
      }
      if (!evento.recurrence) {
        // if we enter here we don't have occurrences. If the event is not in
        // the same day we need to add to filterable_dates all the dates
        let start = moment(evento.start);
        let end = moment(evento.end);
        let between_dates = getDaysBetweenDates(evento, start, end);

        between_dates.forEach((date) => {
          total.push(date);
          // An event should be filterable by all his occurrences
          if (
            !evento.filterable_dates.includes(
              date.locale('it')?.format('DD MMMM'),
            )
          ) {
            evento.filterable_dates.push(date.locale('it')?.format('DD MMMM')); // Used by filters
          }
        });
      }
      return total;
    }, [])
    ?.sort((a, b) => a.toDate() - b.toDate())
    .map((d) => {
      return d?.locale('it')?.format('DD MMMM');
    });
  filters['dates'] = [...new Set(filters['dates'])];

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'baccanale.policy.vocabulary.tipologia_evento',
      }),
    );
    dispatch(
      searchContent(
        '/',
        {
          portal_type: ['Event'],
          fullobjects: 1,
          b_size: 10000,
          sort_on: 'start',
        },
        'eventi',
      ),
    );

    return () => {
      dispatch(resetContent('tipologia_cucina'));
    };
  }, [dispatch]);

  // Mostro numero amssimo di elemnti
  useEffect(() => {
    if (occurrences?.length > 0) {
      const params = queryString.parse(location.search);
      if (Object.keys(params)?.length > 0) {
        let currentEvents = occurrences;
        currentEvents = currentEvents?.sort(
          (a, b) => new Date(a.start) - new Date(b.start),
        );

        Object.keys(params).forEach((name) => {
          handleFilters(name, params[name], true, currentEvents);
        });
      } else {
        setFilteredEventi(occurrences || []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventi, location.search]);

  useEffect(() => {
    let currentEvents = filteredEventi;
    if (Object.keys(filter).length === 0) {
      currentEvents = currentEvents
        ?.sort((a, b) => new Date(a.start) - new Date(b.start))
        ?.filter(
          (m) => m.open_end || !(!m.open_end && moment(m.end).isBefore()),
        );
    }
    setFilteredEventiPaged(
      currentEvents.slice(offset, offset + MAX_ITEMS) || [],
    );
  }, [filter, filteredEventi, offset]);

  // GESTIONE ACCORDION
  const [activeIndex, setActiveIndex] = useState([]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    activeIndex.includes(index)
      ? activeIndex.splice(
          activeIndex.findIndex((ai) => ai === index),
          1,
        )
      : activeIndex.push(index);

    setActiveIndex([...activeIndex]);
  };

  //  GESTIONE FILTRI

  const handleFilters = (name, value, checked, start_events = occurrences) => {
    let currentFilter = filter[name] || [];

    if (checked != null) {
      checked
        ? currentFilter.push(value)
        : currentFilter.splice(
            currentFilter.findIndex((f) => f === value),
            1,
          );
      // Se non c'è nessun filtro ricerco per tutta la categoria
      if (currentFilter.length === 0) {
        // const currentMenus = menus.filter(m => filters[name].includes(m.ristorante.comune));
        delete filter[name];
      } else {
        filter[name] = currentFilter;
      }
    } else {
      // se uguale a null vuol dire che c'è un input text
      if (value !== '') {
        filter[name] = value;
      } else {
        delete filter[name];
      }
    }

    let currentEventi = start_events;
    Object.keys(filter).forEach((f) => {
      switch (f) {
        case 'nome_evento':
          currentEventi = currentEventi.filter((m) =>
            m.title?.toLowerCase()?.includes(value),
          );
          break;

        case 'luoghi':
          currentEventi = currentEventi.filter((m) =>
            filter[f].includes(m.location[0]?.title),
          );
          break;

        case 'dates':
          currentEventi = currentEventi.filter((m) => {
            if (
              filter[f].includes(
                moment(m.start)?.locale('it')?.format('DD MMMM'),
              ) ||
              filter[f].filter((x) => m?.filterable_dates?.includes(x)).length >
                0
            ) {
              return true;
            }
            return false;
          });
          break;

        default:
          currentEventi = currentEventi.filter((m) => {
            const valuesSplitted = m[f.split('.').pop()];
            if (Array.isArray(valuesSplitted)) {
              return filter[f].some((fil) => {
                return valuesSplitted?.find((tc) => {
                  return tc.token === fil;
                });
              });
            } else {
              return filter[f].includes(valuesSplitted);
            }
          });
          break;
      }
    });

    if (Object.keys(filter).length === 0) {
      currentEventi = currentEventi.filter(
        (m) => m.open_end || !(!m.open_end && moment(m.end).isBefore()),
      );
    }
    setFilteredEventi(currentEventi);
    setFilter(filter);
  };

  // GESTIONE CAMBIO PAGINA
  const handlePageClick = (e, data) => {
    window.scrollTo(0, 400);
    const selected = data.activePage - 1;
    offset = Math.ceil(selected * MAX_ITEMS);
    setFilteredEventiPaged(
      filteredEventi.slice(offset, offset + MAX_ITEMS) || [],
    );
  };

  return (
    <>
      <TitleStandard title={content.title} />
      <div className="ui container ricerca-programma">
        <div className="mt-3 text-center">
          <h2>Tutti gli eventi</h2>
        </div>
        <div
          className="flex mt-3 mb-5 flex-wrap"
          style={{ position: 'relative' }}
        >
          <div className="flex items-start w-100 mb-1 flex-wrap">
            <Accordion fluid styled className="mlr-1 mt-05 ">
              <Accordion.Title
                active={activeIndex.includes(0)}
                index={0}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
              >
                <Icon name="dropdown" />
                CERCA PER DATA
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(0)}>
                {filters['dates']?.map((i, index) => (
                  <Form.Checkbox
                    key={index}
                    label={i}
                    name="date"
                    value={i}
                    onChange={(e, { checked }) => {
                      handleFilters('dates', i, checked);
                    }}
                  />
                ))}
              </Accordion.Content>
            </Accordion>
            <Accordion fluid styled className="mlr-1 mt-05">
              <Accordion.Title
                active={activeIndex.includes(1)}
                index={1}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
              >
                <Icon name="dropdown" />
                CERCA PER TIPOLOGIA
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(1)}>
                <Form>
                  <Form.Group grouped>
                    {filters &&
                      filters[
                        'baccanale.policy.vocabulary.tipologia_evento'
                      ]?.items?.map((i, index) => (
                        <Form.Checkbox
                          key={index}
                          label={i.label}
                          name="baccanale.policy.vocabulary.tipologia_evento"
                          value={i.value}
                          checked={
                            filter[
                              'baccanale.policy.vocabulary.tipologia_evento'
                            ] !== undefined &&
                            filter[
                              'baccanale.policy.vocabulary.tipologia_evento'
                            ]?.includes(i.value)
                          }
                          onChange={(e, { checked }) => {
                            handleFilters(
                              'baccanale.policy.vocabulary.tipologia_evento',
                              i.value,
                              checked,
                            );
                          }}
                        />
                      ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>
            </Accordion>
            <Accordion fluid styled className="mlr-1 mt-05">
              <Accordion.Title
                active={activeIndex.includes(2)}
                index={2}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
              >
                <Icon name="dropdown" />
                CERCA PER LUOGO
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(2)}>
                <Form>
                  <Form.Group grouped>
                    {filters['luoghi']?.map((l, index) => (
                      <Form.Checkbox
                        key={index}
                        label={l}
                        name="luoghi"
                        value={l}
                        onChange={(e, { checked }) => {
                          handleFilters('luoghi', l, checked);
                        }}
                      />
                    ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>
            </Accordion>
            <Input
              icon="search"
              placeholder="Nome dell'evento"
              className="search mlr-1 mt-05"
              onChange={(e, { value }) => {
                handleFilters('nome_evento', value.toLowerCase());
              }}
            />
          </div>
          {eventiRequest && (
            <div style={{ position: 'relative', width: '100%' }}>
              <Dimmer active={eventiRequest?.loading} inverted>
                <Loader
                  active={eventiRequest?.loading}
                  size={'medium'}
                  inline="centered"
                  style={{ marginTop: '2rem' }}
                >
                  Caricamento ...
                </Loader>
              </Dimmer>
              {eventi && (
                <ListingEvents events={filteredEventiPaged} fullobjects />
              )}
              {Math.ceil(filteredEventi.length / MAX_ITEMS) > 0 && (
                <Pagination
                  defaultActivePage={1}
                  siblingRange={0}
                  firstItem={null}
                  lastItem={null}
                  prevItem={undefined}
                  nextItem={undefined}
                  ellipsisItem="-"
                  totalPages={Math.ceil(filteredEventi.length / MAX_ITEMS)}
                  onPageChange={handlePageClick}
                  className="pagination w-100 flex justify-center"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(RicercaProgrammaView);
