import { GET_EXPORTED_MENUS } from '../constants/ActionTypes';

export function getExportedMenus() {
  return {
    type: GET_EXPORTED_MENUS,
    request: {
      op: 'get',
      path: `/@export-menus`,
    },
  };
}
