import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faLink } from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
  attachments: {
    id: 'attachments',
    defaultMessage: 'Allegati',
  },
  attachment: {
    id: 'attachment',
    defaultMessage: 'Allegato',
  },
});

const Attachment = ({ title, download_url }) => {
  const intl = useIntl();
  return (
    <p className={'attachment'}>
      <a href={flattenToAppURL(download_url)}>
        {title || `${intl.formatMessage(messages.attachment)}`}
        <FontAwesomeIcon className="ml-1" icon={faArrowDown} />
      </a>
    </p>
  );
};
Attachment.propTypes = {
  title: PropTypes.string,
  download_url: PropTypes.string,
};

/**
 * Attachments view component class.
 * @function Attachments
 * @params {object} content Content object.
 * @params {string} folder name where to find images.
 * @returns {string} Markup of the component.
 */
const Attachments = ({ folder_url, folder_name, title }) => {
  const url = `${flattenToAppURL(folder_url)}/${folder_name}`;
  const searchResults = useSelector((state) => state.search.subrequests);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      searchContent(
        url,
        {
          'path.depth': 1,
          sort_on: 'getObjPositionInParent',
          metadata_fields: '_all',
        },
        folder_name,
      ),
    );
    return () => {
      dispatch(resetSearchContent(folder_name));
    };
  }, [dispatch, folder_url, url, folder_name]);

  const attachments = searchResults?.[folder_name]?.items || [];
  return (
    <div className={'attachments flex'}>
      {attachments?.length > 0 ? (
        <div className="">
          <p>
            <span>{title}:</span>
          </p>
          {attachments.map(function (item, i) {
            let postfix = item.portal_type === 'File' ? 'file' : 'image';
            if (item.portal_type === 'File' || item.portal_type === 'Image') {
              return (
                <Attachment
                  key={item['@id']}
                  title={item.title}
                  download_url={`${item['@id']}/@@download/${postfix}`}
                />
              );
            } else {
              return (
                <p className={'attachment'} key={item['@id']}>
                  <a href={item.getRemoteUrl} target="_blank" rel="noreferrer">
                    {item.title}
                    <FontAwesomeIcon className="ml-1" icon={faLink} />
                  </a>
                </p>
              );
            }
          })}
        </div>
      ) : null}
    </div>
  );
};

Attachments.propTypes = {
  content: PropTypes.object,
  folder_name: PropTypes.string,
  title: PropTypes.string,
};

export default Attachments;
