import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const Body = ({ data, inEdit = false }) => {
  return (
    <ConditionalLink condition={!inEdit} to={data?.link_to?.[0]?.['@id'] || ''}>
      <div
        className="box"
        style={{
          backgroundImage: `url("${flattenToAppURL(
            data.background_image[0]?.[data.background_image[0].image_field]
              ?.scales?.great?.download || '',
          )}")`,
        }}
      >
        <div
          className="diagonal-split-background"
          style={{
            background: `-webkit-linear-gradient(135deg, ${data.color_two} 50%, ${data.color_one} 50%)`,
          }}
        >
          <div className="first-text">{data.text_one}</div>
          <div className="second-text">{data.text_two}</div>
        </div>
      </div>
    </ConditionalLink>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Body.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Body;
