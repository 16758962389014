/**
 * PageView view component.
 * @module components/theme/View/PageView
 */

import {
  getVocabulary,
  resetContent,
  searchContent,
} from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExportedMenus } from '../../actions';
import {
  Accordion,
  Form,
  Icon,
  Input,
  Pagination,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { shuffle } from 'lodash';
import TitleStandard from '../Commons/TitleStandard';
import CardSearch from './Commons/CardSearch';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';
import TextBlocks from './Commons/RichTextBlocks/TextBlocks';

/**
 * PageView view component class.
 * @function PageView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const RicercaMenuView = ({ content }) => {
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [filteredMenusPaged, setFilteredMenusPaged] = useState([]);

  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return state;
  });
  const userId = state.userSession.token
    ? jwtDecode(state.userSession.token).sub
    : null;

  const userLoggedSt = state.users;

  useEffect(() => {
    if (!userLoggedSt?.get?.loading && userId) {
      dispatch(getUser(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const filters = state?.vocabularies;
  const requestMenus = state?.search?.subrequests?.menus;
  const menus = state?.search?.subrequests?.menus?.items;

  let offset = 0;
  const MAX_ITEMS = 6;

  filters['comuni'] = menus?.reduce((total, menu) => {
    const comune = menu?.ristorante?.comune;
    if (!total.includes(comune) && comune) total.push(comune);
    return total;
  }, []);

  const [filter, setFilter] = useState({});

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'baccanale.policy.vocabulary.tipologia_cucina',
      }),
    );
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'baccanale.policy.vocabulary.fascia_prezzo',
      }),
    );
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'baccanale.policy.vocabulary.tipologia_consegna',
      }),
    );
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'baccanale.policy.vocabulary.tipologia_ristorante',
      }),
    );
    dispatch(
      searchContent(
        flattenToAppURL(content['id']),
        {
          portal_type: ['Menu'],
          review_state: 'published',
          fullobjects: 1,
          b_size: 10000,
        },
        'menus',
      ),
    );

    return () => {
      dispatch(resetContent('tipologia_cucina'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, content['@id']]);

  useEffect(() => {
    setFilteredMenus(
      shuffle(
        menus?.filter((m) => m?.tipologia_menu_unico?.length === 0) || [],
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus]);
  // GESTIONE ACCORDION
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    setFilteredMenusPaged(
      shuffle(filteredMenus?.slice(offset, offset + MAX_ITEMS)) || [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMenus, offset]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    activeIndex.includes(index)
      ? activeIndex.splice(
          activeIndex.findIndex((ai) => ai === index),
          1,
        )
      : activeIndex.push(index);
    setActiveIndex([...activeIndex]);
  };

  //  GESTIONE FILTRI
  const handleFilters = (name, value, checked) => {
    let currentFilter = filter[name] || [];
    if (checked != null) {
      checked
        ? currentFilter.push(value)
        : currentFilter.splice(
            currentFilter.findIndex((f) => f === value),
            1,
          );

      filter[name] = currentFilter;
    } else {
      filter[name] = value;
    }

    let currentMenus = menus;
    Object.keys(filter).forEach((f) => {
      switch (f) {
        case 'nome_ristorante':
          currentMenus = currentMenus.filter(
            (m) =>
              (filter[f].length > 0
                ? m.ristorante?.title?.toLowerCase()?.includes(value)
                : true) && m.tipologia_menu_unico.length === 0,
          );
          break;

        case 'comuni':
          currentMenus = currentMenus.filter(
            (m) =>
              (filter[f].length > 0
                ? filter[f].includes(m.ristorante.comune)
                : true) && m.tipologia_menu_unico.length === 0,
          );
          break;

        case 'baccanale.policy.vocabulary.tipologia_ristorante':
          if (filter[f]?.length > 0) {
            currentMenus = currentMenus.filter((m) => {
              const valuesSplitted = m.ristorante[f.split('.').pop()];
              if (valuesSplitted) {
                return filter[f].some((fil) => {
                  return valuesSplitted.token === fil;
                });
              } else {
                return filter[f].includes(m.ristorante[f]);
              }
            });
          }

          break;

        default:
          if (filter[f]?.length > 0) {
            currentMenus = currentMenus.filter((m) => {
              const valuesSplitted = m[f.split('.').pop()];
              if (Array.isArray(valuesSplitted)) {
                return filter[f].some((fil) => {
                  return valuesSplitted?.find((tc) => {
                    return tc.token === fil;
                  });
                });
              } else {
                return filter[f].includes(valuesSplitted);
              }
            });
          }
          break;
      }
    });

    setFilteredMenus(currentMenus);
    setFilter(filter);
  };

  const getColor = (type) => {
    switch (type) {
      case 'ristoranti-e-osterie':
        return 'yellow';
      case 'caffetterie-gastronomie':
        return 'green';
      case 'agriturismi':
        return 'red';
      default:
        return undefined;
    }
  };

  const handlePageClick = (e, data) => {
    window.scrollTo(0, 400);
    const selected = data.activePage - 1;
    offset = Math.ceil(selected * MAX_ITEMS);
    setFilteredMenusPaged(
      filteredMenus.slice(offset, offset + MAX_ITEMS) || [],
    );
  };

  const downloadFile = () => {
    dispatch(getExportedMenus());
  };

  return (
    <>
      <TitleStandard title={content.title} />

      <div className="menu-view ui container ricerca-menu">
        <div
          className={`printPDF ${
            !userLoggedSt?.user?.roles?.includes('Manager') ? 'hidden' : ''
          }`}
        >
          <button className={'printButton'} onClick={downloadFile}>
            Export RTF (2022)
          </button>
        </div>
        <div className="mt-2">
          <TextBlocks content={content} />
        </div>
        <div className="flex mt-5 mb-5 flex-wrap wrapper">
          <div style={{ flex: 1 }}>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex.includes(0)}
                index={0}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
              >
                <Icon name="dropdown" />
                COMUNE
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(0)}>
                {filters['comuni']?.map((i, index) => (
                  <Form.Checkbox
                    key={index}
                    label={i}
                    name="comuni"
                    value={i}
                    onChange={(e, { checked }) => {
                      handleFilters('comuni', i, checked);
                    }}
                  />
                ))}
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex.includes(1)}
                index={1}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
                className="mt-05"
              >
                <Icon name="dropdown" />
                COSA VUOI MANGIARE?
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(1)}>
                <Form>
                  <Form.Group grouped>
                    {filters &&
                      filters[
                        'baccanale.policy.vocabulary.tipologia_cucina'
                      ]?.items?.map((i, index) => (
                        <Form.Checkbox
                          key={index}
                          label={i.label}
                          name="baccanale.policy.vocabulary.tipologia_cucina"
                          value={i.value}
                          onChange={(e, { checked }) => {
                            handleFilters(
                              'baccanale.policy.vocabulary.tipologia_cucina',
                              i.value,
                              checked,
                            );
                          }}
                        />
                      ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex.includes(2)}
                index={2}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
                className="mt-05"
              >
                <Icon name="dropdown" />
                FASCIA DI PREZZO
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(2)}>
                <Form>
                  <Form.Group grouped>
                    {filters &&
                      filters[
                        'baccanale.policy.vocabulary.fascia_prezzo'
                      ]?.items?.map((i, index) => (
                        <Form.Checkbox
                          key={index}
                          label={i.label}
                          name="baccanale.policy.vocabulary.fascia_prezzo"
                          value={i.value}
                          onChange={(e, { checked }) => {
                            handleFilters(
                              'baccanale.policy.vocabulary.fascia_prezzo',
                              i.value,
                              checked,
                            );
                          }}
                        />
                      ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex.includes(3)}
                index={3}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
                className="mt-05"
              >
                <Icon name="dropdown" />
                CONSEGNA/ASPORTO
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(3)}>
                <Form>
                  <Form.Group grouped>
                    {filters &&
                      filters[
                        'baccanale.policy.vocabulary.tipologia_consegna'
                      ]?.items?.map((i, index) => (
                        <Form.Checkbox
                          key={index}
                          label={i.label}
                          name="baccanale.policy.vocabulary.tipologia_consegna"
                          value={i.value}
                          onChange={(e, { checked }) => {
                            handleFilters(
                              'baccanale.policy.vocabulary.tipologia_consegna',
                              i.value,
                              checked,
                            );
                          }}
                        />
                      ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>
              <Accordion.Title
                active={activeIndex.includes(4)}
                index={4}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
                className="mt-05"
              >
                <Icon name="dropdown" />
                TIPOLOGIA LOCALE
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(4)}>
                <Form>
                  <Form.Group grouped>
                    {filters &&
                      filters[
                        'baccanale.policy.vocabulary.tipologia_ristorante'
                      ]?.items?.map((i, index) => (
                        <Form.Checkbox
                          key={index}
                          label={i.label}
                          name="baccanale.policy.vocabulary.tipologia_ristorante"
                          value={i.value}
                          onChange={(e, { checked }) => {
                            handleFilters(
                              'baccanale.policy.vocabulary.tipologia_ristorante',
                              i.value,
                              checked,
                            );
                          }}
                        />
                      ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>
            </Accordion>
            <div className="mt-2">
              Oppure cerca per nome...
              <Input
                icon="search"
                placeholder="Nome del ristorante"
                className="w-100 mt-05"
                onChange={(e, { value }) => {
                  handleFilters('nome_ristorante', value.toLowerCase());
                }}
              />
            </div>
            {/* <div className="mt-1">
              <Link to={flattenToAppURL(`${content['@id']}/ristoranti`)}>
                Vedi tutti i ristoranti{' '}
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  size="xs"
                  className="ml-1"
                />
              </Link>
            </div> */}
          </div>
          {requestMenus && (
            <div className="flex justify-between flex-wrap cards ">
              <Dimmer active={requestMenus?.loading} inverted>
                <Loader
                  active={requestMenus?.loading}
                  size={'medium'}
                  inline="centered"
                >
                  Caricamento ...
                </Loader>
              </Dimmer>
              {filteredMenusPaged?.map((m, index) => (
                <CardSearch
                  key={index}
                  anno={m.anno}
                  titolo={m.title}
                  titolo2={m.ristorante?.title}
                  text={m.ristorante?.tipologia_ristorante?.title}
                  linkTo={m['@id']}
                  badgeColor={getColor(
                    m.ristorante?.tipologia_ristorante?.token,
                  )}
                />
              ))}

              {Math.ceil(filteredMenus.length / MAX_ITEMS) > 0 && (
                <Pagination
                  defaultActivePage={1}
                  siblingRange={0}
                  firstItem={null}
                  lastItem={null}
                  prevItem={undefined}
                  nextItem={undefined}
                  ellipsisItem="-"
                  totalPages={Math.ceil(filteredMenus.length / MAX_ITEMS)}
                  onPageChange={handlePageClick}
                  className="pagination w-100 flex justify-center"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RicercaMenuView;
