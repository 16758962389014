import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/min/locales';
import { useIntl } from 'react-intl';

export const CardCalendar = ({ start, end, recurrence }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  const same_day = start.substring(0, 10) === end.substring(0, 10);
  return (
    <>
      <div>
        {same_day ? (
          <>
            <span className="mr-4p">Il</span>
            <span className="mr-6p">{moment(start).format('DD/MM/YYYY')}</span>
            <span className="mr-4p">Ora:</span>
            <span>{moment(start).format('HH:mm')}</span>
          </>
        ) : (
          <>
            <span className="mr-4p">Dal</span>
            <span className="mr-6p">{moment(start).format('DD/MM/YYYY')}</span>
            <span className="mr-4p">al</span>
            <span className="mr-6p">{moment(end).format('DD/MM/YYYY')}</span>
          </>
        )}
        {recurrence ? (
          <p className="text-left">Vedi il calendario</p>
        ) : same_day ? (
          ''
        ) : (
          <p className="text-left">Tutti i giorni</p>
        )}
        {/* <span className="mr-4p">Ora:</span>
      <span>{moment(start).format('HH:mm')}</span> */}
      </div>
    </>
  );
};

CardCalendar.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default CardCalendar;
