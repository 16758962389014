/**
 * Header reducer.
 * @module reducers/header/header
 */
import { GET_HEADER_DATES } from '../constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,

  header_dates: null,
};

/**
 * HeaderDates reducer.
 * @function header
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function getHeaderDates(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_HEADER_DATES}_PENDING`:
      return {
        ...state,
        header_dates: null,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GET_HEADER_DATES}_SUCCESS`:
      return {
        ...state,
        header_dates: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case `${GET_HEADER_DATES}_FAIL`:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };

    default:
      return state;
  }
}
