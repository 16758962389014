import React, { useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { getContent, resetContent } from '@plone/volto/actions';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const Sponsor = ({ sponsor, isEditMode }) => {
  const dispatch = useDispatch();
  const sponsorUrl = flattenToAppURL(sponsor['@id']);
  const newSponsor = useSelector(
    (state) => state.content.subrequests[sponsorUrl]?.data,
  );

  useEffect(() => {
    dispatch(getContent(sponsorUrl, null, sponsorUrl, null, true));
    return () => {
      dispatch(resetContent(sponsorUrl));
    };
  }, [dispatch, sponsorUrl]);

  return (
    <div className="sponsor mb-1" style={{ width: '300px' }}>
      <ConditionalLink
        condition={!isEditMode}
        to={newSponsor ? flattenToAppURL(newSponsor.remoteUrl) : ''}
      >
        <Image
          src={flattenToAppURL(
            newSponsor?.image?.scales?.preview?.download || '',
          )}
          size="tiny"
          className="img"
        />
      </ConditionalLink>
    </div>
  );
};

export default Sponsor;
