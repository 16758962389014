/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'semantic-ui-react';
import { flattenToAppURL, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { Link, useLocation } from 'react-router-dom';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';
import { map } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import config from '@plone/volto/registry';
import TitleStandard from '../Commons/TitleStandard';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  inThisSection: {
    id: 'In this section',
    defaultMessage: 'In questa sezione',
  },
});

const NewsItemView = ({ content }) => {
  const location = useLocation();
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const intl = useIntl();
  return (
    <>
      {content.title && <TitleStandard title={content.title} />}
      <Container className="view-wrapper">
        {content.description && (
          <p className="documentDescription">{content.description}</p>
        )}
        {content.image && (
          <Image
            className="documentImage"
            alt={content.title}
            title={content.title}
            src={
              content.image['content-type'] === 'image/svg+xml'
                ? flattenToAppURL(content.image.download)
                : flattenToAppURL(content.image.scales.mini.download)
            }
            floated="right"
          />
        )}
        {content.text && (
          <div
            dangerouslySetInnerHTML={{
              __html: flattenHTMLToAppURL(content.text.data),
            }}
          />
        )}
        {hasBlocksData(content) && (
          <>
            {map(content[blocksLayoutFieldname]?.items, (block) => {
              const blockType = content[blocksFieldname]?.[block]?.['@type'];
              if (['title', 'description'].indexOf(blockType) > -1) return null;

              const Block =
                config.blocks.blocksConfig[blockType]?.['view'] || null;
              return Block !== null ? (
                <Block
                  key={block}
                  id={block}
                  properties={content}
                  data={content[blocksFieldname][block]}
                  path={getBaseUrl(location?.pathname || '')}
                />
              ) : (
                <div key={block}>
                  {intl.formatMessage(messages.unknownBlock, {
                    block: content[blocksFieldname]?.[block]?.['@type'],
                  })}
                </div>
              );
            })}
          </>
        )}
        {content.subjects.length > 0 && (
          <div className="tags-wrapper">
            Archiviato sotto:
            {content.subjects?.map((s) => (
              <Link className="ui label" to={`/search?Subject=${s}`} key={s}>
                {s}
              </Link>
            ))}
          </div>
        )}
      </Container>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
