import React from 'react';
import cx from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export const Sidenav = ({ isOpen, close }) => {
  return (
    <>
      <div
        className={cx('sidebar', {
          isOpen: isOpen,
        })}
      >
        <div className="header">
          <FontAwesomeIcon icon={faTimes} onClick={close} />
        </div>
        <div className="body">
          <div className="part-one">
            <ul>
              <li>
                <Link to="/il-baccanale" onClick={close}>
                  <span>Il Baccanale</span>
                </Link>
              </li>
              <li>
                <Link to="/programma" onClick={close}>
                  <span>Programma</span>
                </Link>
              </li>
              <li>
                <Link to="/non-solo-news" onClick={close}>
                  <span>Non solo news</span>
                </Link>
              </li>
              <li>
                <Link to="/i-menu-del-baccanale" onClick={close}>
                  <span>I menu del Baccanale</span>
                </Link>
              </li>
              <li>
                <Link to="/fuorimenu" onClick={close}>
                  <span>Fuorimenu</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="part-two">
            <ul>
              <li>
                <Link to="/hai-una-iniziativa-da-proporre" onClick={close}>
                  <span>Hai una iniziativa da proporre?</span>
                </Link>
              </li>
              <li>
                <Link to="/sei-un-ristoratore" onClick={close}>
                  <span>Sei un ristoratore?</span>
                </Link>
              </li>
              <li>
                <Link to="/area-stampa" onClick={close}>
                  <span>Area stampa</span>
                </Link>
              </li>
              <li>
                <Link to="/contattaci" onClick={close}>
                  <span>Contattaci</span>
                </Link>
              </li>
              <li>
                <Link to="/login" onClick={close}>
                  <span>Area riservata</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
