import React from 'react';
import PropTypes from 'prop-types';
import Block from './Block';
import BodyWrapper from './BodyWrapper';
import { useIntl } from 'react-intl';

const View = ({ data, id }) => {
  const currentIntl = useIntl();

  return (
    <div className="block sponsors">
      <BodyWrapper>
        {data.subblocks.map((subblock, index) => (
          <Block
            key={index}
            data={subblock}
            isEditMode={false}
            intl={currentIntl}
          />
        ))}
      </BodyWrapper>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
};

export default View;
