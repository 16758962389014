import React from 'react';
import { Grid } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  address: {
    id: 'address',
    defaultMessage: 'Indirizzo',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Telefono',
  },
  mobile: {
    id: 'mobile',
    defaultMessage: 'Cellulare',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  open: {
    id: 'open',
    defaultMessage: 'Aperto',
  },
  closed: {
    id: 'closed',
    defaultMessage: 'Giorno di chiusura',
  },
  reservation: {
    id: 'reservation',
    defaultMessage: 'Prenotazione',
  },
  notes: {
    id: 'notes',
    defaultMessage: 'Note',
  },
  website: {
    id: 'website',
    defaultMessage: 'Sito web',
  },
  facebook: {
    id: 'facebook',
    defaultMessage: 'Facebook',
  },
  instagram: {
    id: 'instagram',
    defaultMessage: 'Instagram',
  },
  info: {
    id: 'info',
    defaultMessage: 'Informazioni utili',
  },
});

const RestaurantInfoSection = ({ content }) => {
  const intl = useIntl();

  return (
    <Grid className="anagrafe-container center mt-3 font-size-08 mb-3">
      <span className="strong mb-05">{intl.formatMessage(messages.info)}</span>
      <Grid.Row className="p-0 w-100 flex info-row">
        {content.street && (
          <div className="pv-025 info-span">
            <span className="strong">{`${intl.formatMessage(
              messages.address,
            )}:`}</span>
            <span className="">
              {content.street} - {content.zip_code} {content.city}
            </span>
          </div>
        )}
        {content.telefono && (
          <div className="pv-025 info-span">
            {/* <span className="mr-05 ml-05">{'|'}</span> */}
            <span className="strong">{`${intl.formatMessage(
              messages.phone,
            )}:`}</span>
            <span className="">{content.telefono}</span>
          </div>
        )}
      </Grid.Row>
      <Grid.Row className="p-0 w-100 flex info-row">
        {content.cellulare && (
          <div className="pv-025 info-span">
            {/* <span className="mr-05 ml-05">{'|'}</span>{' '} */}
            <span className="strong">{`${intl.formatMessage(
              messages.mobile,
            )}:`}</span>
            <span className="">{content.cellulare}</span>
          </div>
        )}
      </Grid.Row>
      <Grid.Row className="p-0 w-100 flex info-row">
        {content.email && (
          <div className="pv-025 info-span">
            <span className="strong">{`${intl.formatMessage(
              messages.email,
            )}:`}</span>
            <span className="">
              <a href={`mailto:${content.email}`} className="link">
                {content.email}
              </a>
            </span>
          </div>
        )}
        {content.sito_web && (
          <div className="pv-025 info-span">
            {/* <span className="mr-05 ml-05">{'|'}</span> */}
            <span className="strong">{`${intl.formatMessage(
              messages.website,
            )}:`}</span>
            <span className="">
              <a href={content.sito_web} className="link">
                {content.sito_web}
              </a>
            </span>
          </div>
        )}
      </Grid.Row>
      <Grid.Row className="p-0 w-100 flex info-row">
        {content.facebook && (
          <div className="pv-025 info-span">
            <span className="strong">{`${intl.formatMessage(
              messages.facebook,
            )}:`}</span>
            <span className="">
              <a href={content.facebook} className="link">
                {content.facebook}
              </a>
            </span>
          </div>
        )}
        {content.instagram && (
          <div className="pv-025 info-span">
            {/* <span className="mr-05 ml-05">{'|'}</span>{' '} */}
            <span className="strong">{`${intl.formatMessage(
              messages.instagram,
            )}:`}</span>
            <span className="">
              <a href={content.instagram} className="link">
                {content.instagram}
              </a>
            </span>
          </div>
        )}
      </Grid.Row>
      <Grid.Row className="p-0 w-100 flex info-row">
        {content.apertura && (
          <div className="pv-025 info-span">
            <span className="strong">{`${intl.formatMessage(
              messages.open,
            )}:`}</span>
            <span className="">{content.apertura}</span>
          </div>
        )}
      </Grid.Row>
      <Grid.Row className="p-0 w-100 flex info-row">
        {content.chiusura && (
          <div className="pv-025 info-span">
            <span className="strong">{`${intl.formatMessage(
              messages.closed,
            )}:`}</span>
            <span className="">{content.chiusura}</span>
          </div>
        )}
        {content.prenotazione && (
          <div className="pv-025 info-span">
            {/* <span className="mr-05 ml-05">{'|'}</span> */}
            <span className="strong">{`${intl.formatMessage(
              messages.reservation,
            )}:`}</span>
            <span className="">{content.prenotazione}</span>
          </div>
        )}
      </Grid.Row>
      <Grid.Row className="p-0 w-100 flex info-row">
        {content.notes?.data && content.notes?.data !== '<p><br/></p>' && (
          <div className="pv-025 info-span">
            <span className="strong">{`${intl.formatMessage(
              messages.notes,
            )}:`}</span>
            <span className="">
              {content.notes?.data?.replace(/(<([^>]+)>)/g, '')}
            </span>
          </div>
        )}
      </Grid.Row>
    </Grid>
  );
};
export default RestaurantInfoSection;
