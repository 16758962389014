/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import LogoImage from './logo.png';
import { injectIntl } from 'react-intl';
import { useGoogleAnalytics } from 'volto-google-analytics';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  useGoogleAnalytics();

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      className="footer footer-color"
    >
      <Container>
        <Segment basic className="discreet footer-color">
          <div className="flex justify-center">
            <Image size="tiny" src={LogoImage} />
          </div>
        </Segment>

        <div className="container flex flex-wrap-mobile justify-center mb-5">
          <List>
            {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
            <div role="listitem" className="item">
              Comune di Imola
            </div>
            <div role="listitem" className="item">
              Servizio Attività culturali
            </div>
            <div role="listitem" className="item">
              P.zza Gramsci 21 40026 Imola
            </div>
            <div role="listitem" className="item">
              Tel. 0542.60.2427/2410/2433
            </div>
            <div role="listitem" className="item">
              Fax. 0542.60.23.48
            </div>
            <div role="listitem" className="item">
              <a
                href="mailto:attivita.culturali@comune.imola.bo.it"
                className="white"
              >
                attivita.culturali@comune.imola.bo.it
              </a>
            </div>
          </List>
          <div className="stricted-pc">
            <List bulleted as="ul">
              {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/il-baccanale">
                  Il Baccanale
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/programma">
                  Programma
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/non-solo-news">
                  Non solo news
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/i-menu-del-baccanale">
                  I menu del Baccanale
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/fuorimenu">
                  Fuorimenu
                </Link>
              </List.Item>
            </List>
          </div>
          <div className="stricted-pc">
            <List bulleted as="ul">
              {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/hai-una-iniziativa-da-proporre">
                  Hai un’iniziativa da proporre
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/sei-un-ristoratore">
                  Sei un ristoratore
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/area-stampa">
                  Area stampa
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/contattaci">
                  Contattaci
                </Link>
              </List.Item>
              <List.Item as="li" className="ml-1">
                <Link className="item" to="/login">
                  Area riservata
                </Link>
              </List.Item>
            </List>
          </div>
          <div>
            <List bulleted>
              {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
              Privacy
              <br />
              Nell’impossibilità di contattare personalmente tutte le persone
              fotografate, precisiamo che le stesse possono in qualsiasi momento
              chiedere di essere oscurate in una o più foto inviando una e-mail
              a:{' '}
              <a
                className="white"
                href="mailto:attivita.culturali@comune.imola.bo.it"
              >
                attivita.culturali@comune.imola.bo.it
              </a>
              <Link className="white" to="/privacy-e-cookie-policy">
                <div className="mt-1">Privacy e Cookie Policy</div>
              </Link>
            </List>
          </div>
        </div>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
