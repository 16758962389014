/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

import PageView from './components/View/PageView';
import RicercaMenuView from './components/View/RicercaMenuView';
import RicercaFuorimenuView from './components/View/RicercaFuorimenuView';
import EventView from './components/View/EventView';
import CartellaMenuView from './components/View/CartellaMenuView';
import CartellaRicetteView from './components/View/CartellaRicetteView';

import textSVG from '@plone/volto/icons/text.svg';
import ArgumentsInEvidenceEdit from './components/Blocks/QuotedText/Edit';
import ArgumentsInEvidenceView from './components/Blocks/QuotedText/View';
import RestaurantView from './components/View/RestaurantView';
import NewsletterView from './components/Blocks/Newsletter/View';
import NewsletterEdit from './components/Blocks/Newsletter/Edit';
import SponsorsEdit from './components/Blocks/sponsors/Edit';
import SponsorsView from './components/Blocks/sponsors/View';
import EventsListingTemplate from './components/Blocks/Listing/EventsListingTemplate';
import InEvidenceTemplate from './components/Blocks/Listing/inEvidenceTemplate';
import ImageWithSquareEdit from './components/Blocks/ImageWithSquare/Edit';
import ImageWithSquareView from './components/Blocks/ImageWithSquare/View';
import IconsListerEdit from './components/Blocks/IconsLister/Edit';
import IconsListerView from './components/Blocks/IconsLister/View';
import MenuView from './components/View/MenuView';
import RicettaView from './components/View/RicettaView';
import InCollaborazioneConTemplate from './components/Blocks/Listing/InCollaborazioneConTemplate';
import SiRingrazianoTemplate from './components/Blocks/Listing/SiRingrazianoTemplate';
import RicercaProgrammaView from './components/View/ProgrammaView';
import RicercaRicetteView from './components/View/RicercaRicetteView';
import NonSoloNews from './components/View/NonSoloNews';
import FileView from './components/View/FileView';
import NewsItemView from './components/View/NewsItemView';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  // Listing variations for this site
  const listingVariations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'cardWithImageTemplate',
      isDefault: false,
      title: 'Eventi',
      template: EventsListingTemplate,
      fullobjects: true,
    },
    {
      id: 'InCollaborazioneConTemplate',
      isDefault: false,
      title: 'In Collaborazione Con (Sponsor)',
      template: InCollaborazioneConTemplate,
      fullobjects: true,
    },
    {
      id: 'SiRingrazianoTemplate',
      isDefault: false,
      title: 'Si ringraziano (Sponsor)',
      template: SiRingrazianoTemplate,
      fullobjects: true,
    },
    // { label: 'Card semplice', template: SimpleCardTemplate },
    {
      id: 'inEvidence',
      isDefault: false,
      title: 'Contenuto in evidenza',
      template: InEvidenceTemplate,
      fullobjects: true,
    },
  ];

  const customBlocks = {
    sponsors: {
      id: 'sponsors',
      title: 'Lista di sponsor',
      icon: textSVG,
      group: 'home',
      view: SponsorsView,
      edit: SponsorsEdit,
      estricted: false,
      mostUsed: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    iconsLister: {
      id: 'iconsLister',
      title: 'Lista di icone',
      icon: textSVG,
      group: 'home',
      view: IconsListerView,
      edit: IconsListerEdit,
      restricted: false,
      mostUsed: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    quotedText: {
      id: 'quotedText',
      title: 'Testo quotato',
      icon: textSVG,
      group: 'home',
      view: ArgumentsInEvidenceView,
      edit: ArgumentsInEvidenceEdit,
      restricted: false,
      mostUsed: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    newsletter: {
      id: 'newsletter',
      title: 'Newsletter',
      icon: textSVG,
      group: 'home',
      view: NewsletterView,
      edit: NewsletterEdit,
      restricted: false,
      mostUsed: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    imageWithSquare: {
      id: 'imageWithSquare',
      title: 'Immagine con quadrato',
      icon: textSVG,
      group: 'home',
      view: ImageWithSquareView,
      edit: ImageWithSquareEdit,
      restricted: false,
      mostUsed: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
  };

  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      PloneSite: PageView,
      Ristorante: RestaurantView,
      Event: EventView,
      Menu: MenuView,
      Ricetta: RicettaView,
      File: FileView,
      'News Item': NewsItemView,
    },
    layoutViews: {
      ...config.views.layoutViews,
      cartella_menu: CartellaMenuView,
      cartella_ricette: CartellaRicetteView,
      ricerca_menu: RicercaMenuView,
      ricerca_fuorimenu: RicercaFuorimenuView,
      ricerca_ricette: RicercaRicetteView,
      ricerca_programma: RicercaProgrammaView,
      document_view: PageView,
      non_solo_news: NonSoloNews,
    },
  };

  const customInitialBlocks = {
    Event: ['title', 'text'],
  };

  config.blocks = {
    ...config.blocks,
    blocksConfig: {
      ...config.blocks.blocksConfig,
      ...customBlocks,
    },
    initialBlocks: { ...config.blocks.initialBlocks, ...customInitialBlocks },
  };

  config.blocks.blocksConfig.listing.variations = listingVariations;

  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['it'],
    defaultLanguage: 'it',
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.last_updated =
    '2022-06-06T13:51:00+00:00';
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text = {
    it: {
      title: 'Usiamo i cookie',
      description:
        "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente per fini statistici. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href=\"/privacy-e-cookie-policy\">Cookie Policy</a>.",
    },
    en: {
      title: 'We use cookies',
      description:
        'This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical. For profiling cookies you can decide whether to enable them or not by clicking on the \'Settings\' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href="/privacy-e-cookie-policy">Cookie Policy</a>.',
    },
  };
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling.choices = [
    ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling
      .choices,
    {
      config_key: 'GOOGLEFORMS',
      referenceUrls: ['google.com/forms'],
      text: {
        it: {
          title: 'Google Forms',
          description:
            "I cookie di profilazione di Google permettono di fare analisi di accesso alla pagina e sul comportamento dell'utente, e di mostrarti le pubblicità che potrebbero interessarti di più.",
          //text to show in conditional embed if that cookies are not enabled
          conditional_embed_text:
            'Per vedere il form, accetta i cookie di Google Forms.',
        },
        en: {
          title: 'Google Forms',
          description:
            'Google profiling cookies allow you to analyze page access and user behavior, and to show you the advertisements that may interest you the most.',
          //text to show in conditional embed if that cookies are not enabled
          conditional_embed_text:
            'To view the form, please accept Google Forms cookies.',
        },
      },
    },
  ];

  return config;
}
