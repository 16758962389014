import React from 'react';
import PropTypes from 'prop-types';

import RenderBlocks from './RenderBlocks';
import RichText from './RichText';
import hasBlocksDataExtended from './utils';

/**
 * TextBlocks view component class.
 * @function TextBlocks
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const TextBlocks = ({ content, exclude = ['title', 'description'] }) => {
  /* Render text or blocks in view, skip title and description blocks by default*/

  return (
    <>
      {hasBlocksDataExtended(content, exclude) ? (
        <RenderBlocks content={content} />
      ) : (
        content.text?.data && (
          <RichText serif={false} content={content.text.data} />
        )
      )}
    </>
  );
};

export default TextBlocks;

TextBlocks.propTypes = {
  content: PropTypes.any,
};
