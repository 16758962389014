import React from 'react';
import quoteIcon from './quote.svg';
import { Image } from 'semantic-ui-react';

const Body = ({ data }) => {
  return (
    <>
      <div className="block cinquanta quoted-text">
        <div className="flex justify-center">
          <Image className="icon mr-1" src={quoteIcon} />
        </div>
        <div className="description">{data.description}</div>
      </div>
    </>
  );
};

export default Body;
