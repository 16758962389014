/**
 * Header reducer.
 * @module reducers/header/header
 */
import { GET_EXPORTED_MENUS } from '../constants/ActionTypes';
import { saveAs } from 'file-saver';

const initialState = {
  loaded: false,
  loading: false,
  error: null,

  exported_menus: null,
};

/**
 * ExportedMenus reducer.
 * @function header
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */

function download(result) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/rtf;charset=utf-8,` + result);
  element.setAttribute('download', 'export_menu.rtf');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
}

export default function getExportedMenus(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_EXPORTED_MENUS}_PENDING`:
      return {
        ...state,
        exported_menus: null,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GET_EXPORTED_MENUS}_SUCCESS`:
      download(action.result);
      return {
        ...state,
        exported_menus: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case `${GET_EXPORTED_MENUS}_FAIL`:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };

    default:
      return state;
  }
}
