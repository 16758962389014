import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  si_ringraziano: {
    id: 'si_ringraziano',
    defaultMessage: 'Si ringraziano',
  },
});

const SiRingrazianoTemplate = ({ items, isEditMode, title, linkMore }) => {
  const intl = useIntl();
  return (
    <div className="si-ringraziano-template">
      <div className="px-2">
        <div className="text-center">
          {intl.formatMessage(messages.si_ringraziano)}
        </div>
        <div className="container-link mt-2 flex flex-wrap justify-center">
          {items.map(
            (item, index) =>
              item.image && (
                <ConditionalLink
                  condition={!isEditMode}
                  className="item"
                  to={item.remoteUrl}
                  key={index}
                >
                  <div className="mb-1 description">
                    <Image
                      src={flattenToAppURL(
                        item.image?.scales?.preview?.download || '',
                      )}
                      className="img"
                      size="tiny"
                    ></Image>
                  </div>
                </ConditionalLink>
              ),
          )}
        </div>
        {linkMore?.href && (
          <h4 className="flex justify-center linkmore">
            <ConditionalLink condition={!isEditMode} to={linkMore.href}>
              {linkMore.title}
            </ConditionalLink>
          </h4>
        )}
      </div>
    </div>
  );
};
export default SiRingrazianoTemplate;
