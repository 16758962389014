import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ConditionalLink } from '@plone/volto/components';

const messages = defineMessages({
  in_collaboraizone_con: {
    id: 'in_collaboraizone_con',
    defaultMessage: 'In collaborazione con',
  },
});

const InCollaborazioneConTemplate = ({
  items,
  isEditMode,
  title,
  linkMore,
}) => {
  const intl = useIntl();
  return (
    <div className="in-collaborazione-con-template">
      <div className="px-2">
        <div className="text-center">
          {intl.formatMessage(messages.in_collaboraizone_con)}
        </div>
        <div className="container-link mt-2">
          {items.map((item, index) => (
            <ConditionalLink
              condition={!isEditMode}
              className="item"
              to={item.remoteUrl}
              key={index}
            >
              <div className="mb-1 description">{item.title}</div>
            </ConditionalLink>
          ))}
        </div>
        {linkMore?.href && (
          <h4 className="flex justify-center linkmore">
            <ConditionalLink condition={!isEditMode} to={linkMore.href}>
              {linkMore.title}
            </ConditionalLink>
          </h4>
        )}
      </div>
    </div>
  );
};
export default InCollaborazioneConTemplate;
