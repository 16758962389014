import React from 'react';
import TitleStandard from '../Commons/TitleStandard';
import { Container } from 'semantic-ui-react';
import RestaurantCardWithRibbon from './Commons/Restaurants/RestaurantCardWithRibbon';
import TriangoloGiallo from '../../assets/triangolo_giallo_osteria.svg';

const CartellaRicetteView = ({ content }) => {
  return (
    <>
      <TitleStandard title={`Le ricette di ${content.parent?.title}`} />
      <div
        className="menu-view ui container cartella-ricette-view"
        style={{ marginBottom: '30px' }}
      >
        <Container>
          <div className="mt-1">
            {content?.items?.map((ricetta) => (
              <RestaurantCardWithRibbon
                title={ricetta.title}
                linkID={ricetta['@id']}
                description={ricetta.description}
                svg={TriangoloGiallo}
                color={'senape'}
                icon_color={'#dbcb4f'}
                year={ricetta.anno}
              />
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default CartellaRicetteView;
