import React from 'react';
import TitleStandard from '../Commons/TitleStandard';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image, Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import frecciaDX from '../../assets/customIcons/freccia_dx_scheda.svg';
import { Link } from 'react-router-dom';
import { OSMMap } from 'volto-venue';
import RestaurantInfoSection from './Commons/Restaurants/RestaurantInfoSection';
import PrintPDF from './Commons/PrintPDF';

const messages = defineMessages({
  procedimento: {
    id: 'procedimento',
    defaultMessage: 'Procedimento',
  },
  scarica: {
    id: 'scarica',
    defaultMessage: 'Scarica',
  },
});

const RicettaView = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      <TitleStandard title={content.title} />
      <div className="menu-view ui container ricetta-view">
        <PrintPDF item={content} />
        <div className="super-padding mt-3">
          <div>
            <Image
              src={flattenToAppURL(
                content?.image?.scales?.preview?.download || '',
              )}
              centered
              className="img"
            />
          </div>

          {/*  RICETTA */}
          {content.procedimento_ricetta?.data?.replace(/(<([^>]+)>)/g, '')
            ?.length > 0 && (
            <div className="mt-1 text-center mb-2">
              <h2>{intl.formatMessage(messages.procedimento)}</h2>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.procedimento_ricetta?.data,
                  }}
                />
              </div>
            </div>
          )}
          {content.file && (
            <div className="flex justify-center mb-2">
              <Button
                size="large"
                className="down-button"
                onClick={() => {
                  window.location = content.file?.download;
                }}
              >
                {intl.formatMessage(messages.scarica)}
              </Button>
            </div>
          )}
        </div>
        {/*  INFO RISTORANTE */}
        {content?.ristorante && Object.keys(content.ristorante).length > 0 && (
          <>
            <div className="text-center ristorante">
              <div className="px-2">
                <div>lo trovi presso</div>
                <div className="title">{content.ristorante?.title}</div>
                <div>{content.ristorante?.tipologia_ristorante?.title}</div>
                <Link
                  to={
                    content.ristorante
                      ? flattenToAppURL(content?.ristorante['@id'] || '')
                      : null
                  }
                >
                  <Image className="mt-05" src={frecciaDX} centered></Image>
                </Link>
              </div>

              <RestaurantInfoSection content={content.ristorante} />
            </div>

            {/* Mappa */}
            {__CLIENT__ && content?.ristorante?.geolocation && (
              <div className="fullwidth-map">
                <OSMMap
                  markers={[
                    {
                      latitude: content.ristorante?.geolocation?.latitude || 0,
                      longitude:
                        content.ristorante?.geolocation?.longitude || 0,
                    },
                  ]}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RicettaView;
