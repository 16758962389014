import { GET_HEADER_DATES } from '../constants/ActionTypes';

export function getHeaderDates() {
  return {
    type: GET_HEADER_DATES,
    request: {
      op: 'get',
      path: `/@header-settings`,
    },
  };
}
