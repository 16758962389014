import React from 'react';
import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import QuotedTextSidebar from './QuotedTextSidebar';
import Body from './Body';

const Edit = ({
  block,
  data,
  pathname,
  selected,
  onChangeBlock,
  openObjectBrowser,
}) => {
  return (
    <>
      <Body data={data} />
      <SidebarPortal selected={selected}>
        <QuotedTextSidebar
          block={block}
          data={data}
          onChangeBlock={onChangeBlock}
          openObjectBrowser={openObjectBrowser}
        />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pathname: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default Edit;
