/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import voltoGdprPrivacy from 'volto-gdpr-privacy';
import voltoMultilingualWidget from 'volto-multilingual-widget';
import voltoRssBlock from 'volto-rss-block';
import voltoVenue from 'volto-venue';
import voltoSubblocks from 'volto-subblocks';
import voltoGoogleAnalytics from 'volto-google-analytics';

const addonsInfo = [{"name":"volto-gdpr-privacy","version":"1.3.8","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/opt/baccanale/baccanale-volto-2/node_modules/volto-gdpr-privacy/src","packageJson":"/opt/baccanale/baccanale-volto-2/node_modules/volto-gdpr-privacy/package.json","addons":[]},{"name":"volto-multilingual-widget","version":"2.2.4","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/opt/baccanale/baccanale-volto-2/node_modules/volto-multilingual-widget/src","packageJson":"/opt/baccanale/baccanale-volto-2/node_modules/volto-multilingual-widget/package.json","addons":[]},{"name":"volto-rss-block","version":"2.3.2","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/opt/baccanale/baccanale-volto-2/node_modules/volto-rss-block/src","packageJson":"/opt/baccanale/baccanale-volto-2/node_modules/volto-rss-block/package.json","addons":[]},{"name":"volto-venue","version":"3.2.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/opt/baccanale/baccanale-volto-2/node_modules/volto-venue/src","packageJson":"/opt/baccanale/baccanale-volto-2/node_modules/volto-venue/package.json","addons":[]},{"name":"volto-subblocks","version":"1.2.3","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/opt/baccanale/baccanale-volto-2/node_modules/volto-subblocks/src","packageJson":"/opt/baccanale/baccanale-volto-2/node_modules/volto-subblocks/package.json","addons":[]},{"name":"volto-google-analytics","version":"1.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/opt/baccanale/baccanale-volto-2/node_modules/volto-google-analytics/src","packageJson":"/opt/baccanale/baccanale-volto-2/node_modules/volto-google-analytics/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [voltoGdprPrivacy, voltoMultilingualWidget, voltoRssBlock, voltoVenue, voltoSubblocks, voltoGoogleAnalytics];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
