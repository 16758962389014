import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Accordion } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon, ObjectBrowserWidget, TextWidget } from '@plone/volto/components';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
  link: {
    id: 'link',
    defaultMessage: 'Collegamento',
  },
});

const SponsorsSidebar = ({
  data,
  block,
  onChangeBlock,
  onChangeSubBlock,
  selected = 0,
  setSelected,
}) => {
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="default" defaultMessage="Default" />
        </h2>
      </header>
      <Accordion fluid styled className="form">
        {data.subblocks &&
          data.subblocks.map((subblock, index) => {
            return (
              <div key={'subblock' + index}>
                <Accordion.Title
                  active={selected === index}
                  index={index}
                  onClick={() => setSelected(selected === index ? null : index)}
                >
                  {`icona in posizione ${index}`}
                  {selected === index ? (
                    <Icon name={upSVG} size="20px" />
                  ) : (
                    <Icon name={downSVG} size="20px" />
                  )}
                </Accordion.Title>
                <Accordion.Content active={selected === index}>
                  <TextWidget
                    id="ArgumentsTitle"
                    title={intl.formatMessage(messages.description)}
                    value={subblock.description}
                    onChange={(name, value) => {
                      onChangeSubBlock(index, {
                        ...subblock,
                        description: value,
                      });
                    }}
                  />
                  <ObjectBrowserWidget
                    id={'ObjectBrowserWidget'}
                    title={intl.formatMessage(messages.link)}
                    value={subblock.sponsors}
                    widgetOptions={{
                      pattern_options: {
                        selectableTypes: ['Link'],
                      },
                    }}
                    onChange={(name, value) => {
                      onChangeSubBlock(index, {
                        ...subblock,
                        sponsors: value,
                      });
                    }}
                  />
                </Accordion.Content>
              </div>
            );
          })}
      </Accordion>
    </Segment.Group>
  );
};

SponsorsSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};

export default injectIntl(SponsorsSidebar);
