/**
 * NonSoloNews view component.
 * @module components/theme/View/NonSoloNews
 */

import React, { useEffect } from 'react';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';
import { map } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Segment, Grid, Container } from 'semantic-ui-react';
import TitleStandard from '../Commons/TitleStandard';
import { useDispatch } from 'react-redux';
import { getContent, resetContent } from '@plone/volto/actions';
import config from '@plone/volto/registry';

/**
 * NonSoloNews view component class.
 * @function NonSoloNews
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  inThisSection: {
    id: 'In this section',
    defaultMessage: 'In questa sezione',
  },
});

const NonSoloNews = ({ content }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const intl = useIntl();
  const location = useLocation();
  const pathname = __CLIENT__ && window.location.pathname.split('/')[1];
  const dispatch = useDispatch();

  useEffect(() => {
    pathname &&
      pathname !== '' &&
      dispatch(getContent(`/${pathname}`, null, pathname, null, true));
    return () => {
      pathname && pathname !== '' && dispatch(resetContent(pathname));
    };
  }, [dispatch, pathname]);

  return hasBlocksData(content) ? (
    <>
      <TitleStandard title={content.title} />
      <div id="page-document" className="ui container non-solo-news">
        <Segment basic>
          <div className="container">
            <Grid>
              <Grid.Column mobile={12} tablet={12} computer={12}>
                {/* Render other blocks in view, skip title and description */}
                {map(content[blocksLayoutFieldname]?.items, (block) => {
                  const blockType =
                    content[blocksFieldname]?.[block]?.['@type'];
                  if (['title', 'description'].indexOf(blockType) > -1)
                    return null;

                  const Block =
                    config.blocks.blocksConfig[blockType]?.['view'] || null;
                  return Block !== null ? (
                    <Block
                      key={block}
                      id={block}
                      properties={content}
                      data={content[blocksFieldname][block]}
                      path={getBaseUrl(location?.pathname || '')}
                    />
                  ) : (
                    <div key={block}>
                      {intl.formatMessage(messages.unknownBlock, {
                        block: content[blocksFieldname]?.[block]?.['@type'],
                      })}
                    </div>
                  );
                })}
              </Grid.Column>
            </Grid>
          </div>
        </Segment>
      </div>
    </>
  ) : (
    <Container id="page-document" className="non-solo-news">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: content.text.data,
          }}
        />
      )}
    </Container>
  );
};

export default NonSoloNews;
