import React from 'react';
import TitleStandard from '../Commons/TitleStandard';
import { Container } from 'semantic-ui-react';
import RestaurantCardWithRibbon from './Commons/Restaurants/RestaurantCardWithRibbon';
import TriangoloRosso from '../../assets/triangolo_rosso_agriturismo.svg';

const CartellaMenuView = ({ content }) => {
  return (
    <>
      <TitleStandard title={`I menu di ${content.parent?.title}`} />
      <div
        className="menu-view ui container cartella-menu-view"
        style={{ marginBottom: '30px' }}
      >
        <Container>
          <div className="mt-1">
            {content?.items?.map((menu) => (
              <RestaurantCardWithRibbon
                key={menu['@id']}
                title={menu.title}
                linkID={menu['@id']}
                description={menu.description}
                svg={TriangoloRosso}
                color={'primary-color'}
                icon_color={'#db564f'}
                year={menu.anno}
              />
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default CartellaMenuView;
