/**
 * Search component.
 * @module components/theme/Search/Search
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'redux-connect';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import {
  Container,
  Pagination,
  Button,
  Header,
  Input,
} from 'semantic-ui-react';
import qs from 'query-string';
import classNames from 'classnames';

import { Helmet } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { SearchTags, Toolbar, Icon } from '@plone/volto/components';

import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';

import config from '@plone/volto/registry';

/**
 * Search class.
 * @class SearchComponent
 * @extends Component
 */
class Search extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    searchableText: PropTypes.string,
    subject: PropTypes.string,
    path: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: [],
    searchableText: null,
    subject: null,
    path: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isClient: false,
      active: 'relevance',
      searchString: this.props.searchableText || '',
      subject: this.props.subject,
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.setState({ isClient: true });
    this.doSearch();
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search !== nextProps.location.search) {
      this.doSearch();
    }
  };

  /**
   * Search based on the given searchableText, subject and path.
   * @method doSearch
   * @param {string} searchableText The searchable text string
   * @param {string} subject The subject (tag)
   * @param {string} path The path to restrict the search to
   * @returns {undefined}
   */

  doSearch = () => {
    const options = qs.parse(this.props.history.location.search);
    if (options.Subject)
      this.setState({
        ...this.state,
        subject: options.Subject,
      });
    this.setState({ currentPage: 1 });
    this.props.searchContent('', options);
  };

  handleQueryPaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let options = qs.parse(this.props.history.location.search);
    this.setState({ currentPage: activePage }, () => {
      this.props.searchContent('', {
        ...options,
        b_start: (this.state.currentPage - 1) * config.settings.defaultPageSize,
      });
    });
  };

  onSortChange = (event, sort_order) => {
    let options = qs.parse(this.props.history.location.search);
    options.sort_on = event.target.name;
    options.sort_order = sort_order || 'ascending';
    if (event.target.name === 'relevance') {
      delete options.sort_on;
      delete options.sort_order;
    }
    let searchParams = qs.stringify(options);
    this.setState({ currentPage: 1, active: event.target.name }, () => {
      // eslint-disable-next-line no-restricted-globals
      this.props.history.replace({
        search: searchParams,
      });
    });
  };

  handleKeypress = (e) => {
    if (e.charCode === 13) {
      window.location = `/search?SearchableText=${this.state.searchString}`;
    }
    if (e.keyCode === 13) {
      window.location = `/search?SearchableText=${this.state.searchString}`;
    }
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container id="page-search">
        <Helmet title="Search" />
        <div className="container">
          <div className="flex justify-center mb-2">
            <Input
              placeholder="Search..."
              style={{ width: '50%' }}
              value={this.state.searchString}
              onChange={(e, { value }) => {
                this.setState({ searchString: value });
              }}
              onKeyPress={(e) => {
                this.handleKeypress(e);
              }}
            />
            <Button
              attached="right"
              style={{ marginLeft: '-10px' }}
              onClick={(e) => {
                window.location = `/search?SearchableText=${this.state.searchString}`;
              }}
            >
              Cerca
            </Button>
          </div>

          {(this.props.searchableText || this.state.subject) && (
            <article id="content">
              <header>
                <h1 className="documentFirstHeading">
                  {this.props.searchableText || this.state.subject ? (
                    <FormattedMessage
                      id="Search results for {category}{term}"
                      defaultMessage="Risultati trovati per {category}{term}"
                      values={{
                        category: this.state.subject ? 'la categoria ' : '',
                        term: (
                          <q>
                            {this.props.searchableText || this.state.subject}
                          </q>
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="Search results"
                      defaultMessage="Ricerca"
                    />
                  )}
                </h1>

                <SearchTags />

                {this.props.items.length > 0 ? (
                  <div className="items_total">
                    {this.props.items.length}{' '}
                    <FormattedMessage
                      id="results found"
                      defaultMessage="Risultati"
                    />
                    <Header>
                      <Header.Content className="header-content">
                        <div className="sort-by">
                          <FormattedMessage
                            id="Sort By:"
                            defaultMessage="Ordina per:"
                          />
                        </div>
                        <Button
                          onClick={(event) => {
                            this.onSortChange(event);
                          }}
                          name="relevance"
                          size="tiny"
                          className={classNames('button-sort', {
                            'button-active': this.state.active === 'relevance',
                          })}
                        >
                          <FormattedMessage
                            id="Relevance"
                            defaultMessage="Rilevanza"
                          />
                        </Button>
                        <Button
                          onClick={(event) => {
                            this.onSortChange(event);
                          }}
                          name="sortable_title"
                          size="tiny"
                          className={classNames('button-sort', {
                            'button-active':
                              this.state.active === 'sortable_title',
                          })}
                        >
                          <FormattedMessage
                            id="Alphabetically"
                            defaultMessage="Alfabetico"
                          />
                        </Button>
                        <Button
                          onClick={(event) => {
                            this.onSortChange(event, 'reverse');
                          }}
                          name="effective"
                          size="tiny"
                          className={classNames('button-sort', {
                            'button-active': this.state.active === 'effective',
                          })}
                        >
                          <FormattedMessage
                            id="Date (newest first)"
                            defaultMessage="Data (nuovi prima)"
                          />
                        </Button>
                      </Header.Content>
                    </Header>
                  </div>
                ) : (
                  <div>
                    <FormattedMessage
                      id="No results found"
                      defaultMessage="No results found"
                    />
                  </div>
                )}
              </header>
              <section id="content-core">
                {this.props.items.map((item) => (
                  <article className="tileItem" key={item['@id']}>
                    <h2 className="tileHeadline">
                      <Link
                        to={item['@id']}
                        className="summary url"
                        title={item['@type']}
                      >
                        {item.title}
                      </Link>
                    </h2>
                    {item.description && (
                      <div className="tileBody">
                        <span className="description">{item.description}</span>
                      </div>
                    )}
                    <div className="tileFooter">
                      <Link to={item['@id']}>
                        <FormattedMessage
                          id="Read More…"
                          defaultMessage="Leggi ancora…"
                        />
                      </Link>
                    </div>
                    <div className="visualClear" />
                  </article>
                ))}
                {this.props.search?.batching && (
                  <div className="search-footer">
                    <Pagination
                      activePage={this.state.currentPage}
                      totalPages={Math.ceil(
                        this.props.search.items_total /
                          config.settings.defaultPageSize,
                      )}
                      onPageChange={this.handleQueryPaginationChange}
                      firstItem={null}
                      lastItem={null}
                      prevItem={{
                        content: <Icon name={paginationLeftSVG} size="18px" />,
                        icon: true,
                        'aria-disabled': !this.props.search.batching.prev,
                        className: !this.props.search.batching.prev
                          ? 'disabled'
                          : null,
                      }}
                      nextItem={{
                        content: <Icon name={paginationRightSVG} size="18px" />,
                        icon: true,
                        'aria-disabled': !this.props.search.batching.next,
                        className: !this.props.search.batching.next
                          ? 'disabled'
                          : null,
                      }}
                    />
                  </div>
                )}
              </section>
            </article>
          )}
        </div>
        {this.state.isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              hideDefaultViewButtons
              inner={<span />}
            />
          </Portal>
        )}
      </Container>
    );
  }
}

export const __test__ = connect(
  (state, props) => ({
    items: state.search.items,
    searchableText: qs.parse(props.history.location.search).SearchableText,
    pathname: props.history.location.pathname,
  }),
  { searchContent },
)(Search);

export default compose(
  connect(
    (state, props) => ({
      items: state.search.items,
      searchableText: qs.parse(props.history.location.search).SearchableText,
      pathname: props.location.pathname,
    }),
    { searchContent },
  ),
  asyncConnect([
    {
      key: 'search',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(searchContent('', qs.parse(location.search))),
    },
  ]),
)(Search);
