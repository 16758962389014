/**
 * PageView view component.
 * @module components/theme/View/PageView
 */

import React, { useEffect, useState } from 'react';
import { Input, Pagination } from 'semantic-ui-react';
import TitleStandard from '../Commons/TitleStandard';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVocabulary,
  resetContent,
  searchContent,
} from '@plone/volto/actions';
import { Accordion, Icon, Form, Loader, Dimmer } from 'semantic-ui-react';
import CardSearch from './Commons/CardSearch';
import { shuffle } from 'lodash';

/**
 * PageView view component class.
 * @function PageView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const RicercaRicetteView = ({ content }) => {
  const [filteredRicette, setFilteredRicette] = useState([]);
  const [filteredRicettePaged, setFilteredRicettePaged] = useState([]);

  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return state;
  });
  const filters = state?.vocabularies;
  const ricette = state?.search?.subrequests?.ricette?.items;
  const ricetteRequest = state?.search?.subrequests?.ricette;

  let offset = 0;
  const MAX_ITEMS = 6;

  filters['autori'] = ricette?.reduce((total, ricetta) => {
    const autore = ricetta?.autore || ricetta?.ristorante?.title;
    if (autore == null) return total;

    if (!total.includes(autore)) total.push(autore);
    return total;
  }, []);

  filters['anno'] = ricette?.reduce((total, ricetta) => {
    const anno = ricetta?.anno;
    if (!total.includes(anno)) total.push(anno);
    return total;
  }, []);

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'baccanale.policy.vocabulary.tipologia_cucina',
      }),
    );
    dispatch(
      searchContent(
        '/',
        { portal_type: ['Ricetta'], fullobjects: 1, b_size: 10000 },
        'ricette',
      ),
    );

    return () => {
      dispatch(resetContent('tipologia_cucina'));
    };
  }, [dispatch]);

  useEffect(() => {
    setFilteredRicette(shuffle(ricette) || []);
  }, [ricette]);
  // GESTIONE ACCORDION
  const [activeIndex, setActiveIndex] = useState([0]);

  useEffect(() => {
    setFilteredRicettePaged(
      shuffle(filteredRicette?.slice(offset, offset + MAX_ITEMS)) || [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRicette, offset]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    activeIndex.includes(index)
      ? activeIndex.splice(
          activeIndex.findIndex((ai) => ai === index),
          1,
        )
      : activeIndex.push(index);

    setActiveIndex([...activeIndex]);
  };

  //  GESTIONE FILTRI
  const [filter, setFilter] = useState({});

  const handleFilters = (name, value, checked) => {
    let currentFilter = filter[name] || [];

    if (checked != null) {
      checked
        ? currentFilter.push(value)
        : currentFilter.splice(
            currentFilter.findIndex((f) => f === value),
            1,
          );
      // Se non c'è nessun filtro ricerco per tutta la categoria
      if (currentFilter.length === 0) {
        delete filter[name];
      } else {
        filter[name] = currentFilter;
      }
    } else {
      // se uguale a null vuol dire che c'è un input text
      if (value !== '') {
        filter[name] = value;
      } else {
        delete filter[name];
      }
    }

    let currentRicette = ricette;
    Object.keys(filter).forEach((f) => {
      switch (f) {
        case 'nome_ricetta':
          currentRicette = currentRicette.filter((m) =>
            m.title?.toLowerCase()?.includes(value),
          );
          break;

        case 'autori':
          currentRicette = currentRicette.filter((m) =>
            filter[f].includes(m.ristorante?.title || m.autore),
          );
          break;

        case 'anno':
          currentRicette = currentRicette.filter((m) =>
            filter[f].includes(m.anno),
          );
          break;
        case 'baccanale.policy.vocabulary.tipologia_ristorante':
          currentRicette = currentRicette.filter((m) => {
            const valuesSplitted = m.ristorante[name.split('.').pop()];
            if (Array.isArray(valuesSplitted)) {
              return filter[f].some((f) => {
                return valuesSplitted?.find((tc) => {
                  return tc.token === f;
                });
              });
            } else {
              return filter[f].includes(m.ristorante[f]);
            }
          });
          break;

        default:
          currentRicette = currentRicette.filter((m) => {
            const valuesSplitted = m[f.split('.').pop()];
            if (Array.isArray(valuesSplitted)) {
              return filter[f].some((fil) => {
                return valuesSplitted?.find((tc) => {
                  return tc.token === fil;
                });
              });
            } else {
              return filter[f].includes(valuesSplitted);
            }
          });
          break;
      }
    });

    setFilteredRicette(currentRicette);
    setFilter(filter);
  };

  const handlePageClick = (e, data) => {
    window.scrollTo(0, 400);
    const selected = data.activePage - 1;
    offset = Math.ceil(selected * MAX_ITEMS);
    setFilteredRicettePaged(
      filteredRicette.slice(offset, offset + MAX_ITEMS) || [],
    );
  };

  return (
    <>
      <TitleStandard title={content.title} />
      <div className="menu-view ui container ricerca-menu">
        <div className="mt-5">{content.description}</div>
        <div className="flex mt-5 mb-5 flex-wrap wrapper">
          <div style={{ flex: 1 }}>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex.includes(0)}
                index={0}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
              >
                <Icon name="dropdown" />
                AUTORE
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(0)}>
                {filters['autori']?.map((i, index) => (
                  <Form.Checkbox
                    key={index}
                    label={i}
                    name="autori"
                    value={i}
                    onChange={(e, { checked }) => {
                      handleFilters('autori', i, checked);
                    }}
                  />
                ))}
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex.includes(1)}
                index={1}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
                className="mt-05"
              >
                <Icon name="dropdown" />
                TIPOLOGIA CUCINA
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(1)}>
                <Form>
                  <Form.Group grouped>
                    {filters &&
                      filters[
                        'baccanale.policy.vocabulary.tipologia_cucina'
                      ]?.items?.map((i, index) => (
                        <Form.Checkbox
                          key={index}
                          label={i.label}
                          name="baccanale.policy.vocabulary.tipologia_cucina"
                          value={i.value}
                          onChange={(e, { checked }) => {
                            handleFilters(
                              'baccanale.policy.vocabulary.tipologia_cucina',
                              i.value,
                              checked,
                            );
                          }}
                        />
                      ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex.includes(2)}
                index={2}
                onClick={(e, titleProps) => {
                  handleClick(e, titleProps);
                }}
                className="mt-05"
              >
                <Icon name="dropdown" />
                ANNO
              </Accordion.Title>
              <Accordion.Content active={activeIndex.includes(2)}>
                <Form>
                  <Form.Group grouped>
                    {filters['anno']?.map((i, index) => (
                      <Form.Checkbox
                        key={index}
                        label={i}
                        name="anno"
                        value={i}
                        onChange={(e, { checked }) => {
                          handleFilters('anno', i, checked);
                        }}
                      />
                    ))}
                  </Form.Group>
                </Form>
              </Accordion.Content>
            </Accordion>
            <div className="mt-2">
              Oppure cerca per nome...
              <Input
                icon="search"
                placeholder="Cerca..."
                className="w-100 mt-05"
                onChange={(e, { value }) => {
                  handleFilters('nome_ricetta', value.toLowerCase());
                }}
              />
            </div>
          </div>
          {ricetteRequest && (
            <div className="flex justify-between flex-wrap cards ricette">
              <Dimmer active={ricetteRequest?.loading} inverted>
                <Loader
                  active={ricetteRequest?.loading}
                  size={'medium'}
                  inline="centered"
                >
                  Caricamento ...
                </Loader>
              </Dimmer>
              {filteredRicettePaged?.map((m, index) => (
                <CardSearch
                  key={index}
                  anno={m.anno}
                  titolo={m.title}
                  titolo2={m.ristorante?.title || m.autore}
                  linkTo={m['@id']}
                  badgeColor={'gold'}
                />
              ))}
              {Math.ceil(filteredRicette.length / MAX_ITEMS) > 0 && (
                <Pagination
                  defaultActivePage={1}
                  siblingRange={0}
                  firstItem={null}
                  lastItem={null}
                  prevItem={undefined}
                  nextItem={undefined}
                  ellipsisItem="-"
                  totalPages={Math.ceil(filteredRicette.length / MAX_ITEMS)}
                  onPageChange={handlePageClick}
                  className="pagination w-100 flex justify-center"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RicercaRicetteView;
