import React from 'react';
import { OSMMap } from 'volto-venue';
import PropTypes from 'prop-types';

/**
 * REstaurantMap view component class.
 * @function EventLocationMap
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const RestaurantMap = ({ geolocation }) => {
  return (
    <>
      {__CLIENT__ && geolocation?.latitude && geolocation?.longitude ? (
        <>
          <OSMMap
            markers={[
              {
                latitude: geolocation?.latitude || 0,
                longitude: geolocation?.longitude || 0,
              },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export default RestaurantMap;

RestaurantMap.propTypes = {
  geolocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};
