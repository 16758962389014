import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { TextWidget, ObjectBrowserWidget } from '@plone/volto/components';
import ColorWidget from '../../manage/Widgets/ColorWidget';

const messages = defineMessages({
  News: {
    id: 'News',
    defaultMessage: 'News',
  },
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
  text_one: {
    id: 'text_one',
    defaultMessage: 'Testo in alto a sinistra',
  },
  text_two: {
    id: 'text_two',
    defaultMessage: 'Testo in basso a destra',
  },
  color_one: {
    id: 'color_one',
    defaultMessage: 'Colore in alto a sinistra',
  },
  color_two: {
    id: 'color_two',
    defaultMessage: 'Colore in basso a destra',
  },
  link: {
    id: 'link',
    defaultMessage: 'Collegamento',
  },
  image: {
    id: 'image',
    defaultMessage: 'Immagine',
  },
});

const ImageWithSquareSidebar = ({ block, data, onChangeBlock, required }) => {
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="NewsHome"
            defaultMessage="News con immagine in primo piano"
          />
        </h2>
      </header>

      <Segment className="form">
        <TextWidget
          id="text_one"
          title={intl.formatMessage(messages.text_one)}
          required={false}
          value={data.text_one}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <ColorWidget
          id="color_one"
          title={intl.formatMessage(messages.color_one)}
          required={false}
          value={data.color_one}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <TextWidget
          id="text_two"
          title={intl.formatMessage(messages.text_two)}
          required={false}
          value={data.text_two}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <ColorWidget
          id="color_two"
          title={intl.formatMessage(messages.color_two)}
          required={false}
          value={data.color_two}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />

        <ObjectBrowserWidget
          id={'background_image'}
          title={intl.formatMessage(messages.image)}
          value={data.background_image}
          widgetOptions={{
            pattern_options: {
              selectableTypes: ['Image'],
            },
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              background_image: value,
            });
          }}
        />

        <ObjectBrowserWidget
          id={'ObjectBrowserWidget'}
          title={intl.formatMessage(messages.link)}
          mode={'link'}
          value={data.link_to}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              link_to: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

ImageWithSquareSidebar.propTypes = {
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default ImageWithSquareSidebar;
