import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'semantic-ui-react';

import badgeRed from '../../../assets/customIcons/triangolo_rosso_agriturismo.svg';
import badgeGreen from '../../../assets/customIcons/triangolo_verde_ristorante.svg';
import badgeYellow from '../../../assets/customIcons/triangolo_giallo_osteria.svg';
import badgeGold from '../../../assets/customIcons/triangolo_ricetta.svg';

/**
 * RichText view component class.
 * @function RichText
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const CardSearch = ({ anno, titolo, titolo2, text, linkTo, badgeColor }) => {
  const getIcon = (color) => {
    switch (color) {
      case 'red':
        return badgeRed;
      case 'green':
        return badgeGreen;
      case 'yellow':
        return badgeYellow;
      case 'gold':
        return badgeGold;
      default:
        return badgeRed;
    }
  };

  return (
    <div className="card-search px-1 mb-2">
      <Link className="item" to={flattenToAppURL(linkTo)}>
        {badgeColor && (
          <Image src={getIcon(badgeColor)} size="mini" className="img" />
        )}
        <div className="anno">{anno}</div>
        <div className="mt-1 title">{titolo}</div>
        <div className="mt-05 title2">{titolo2}</div>
        <div className="mt-2 text">{text}</div>
        <div className="flex align-center justify-center mt-05">
          <FontAwesomeIcon icon={faArrowCircleRight} size="lg" />
        </div>
      </Link>
    </div>
  );
};
export default CardSearch;

CardSearch.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  add_class: PropTypes.string,
  title_size: PropTypes.string,
};
