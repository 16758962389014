import React from 'react';
import { Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';

const Body = ({ data, inEditMode }) => {
  return (
    <ConditionalLink
      condition={!inEditMode}
      to={
        data?.type
          ? `/programma?baccanale.policy.vocabulary.tipologia_evento=${data.type}`
          : '/programma'
      }
    >
      <div>
        <div className="icon-container">
          <Image src={data.icon} size="tiny" className="img" />
          <div className="other"></div>
        </div>
        <div className="text">{data.description}</div>
      </div>
    </ConditionalLink>
  );
};

export default Body;
