import React from 'react';
import { Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import bgImage from '../../../assets/bg.png';
import cx from 'classnames';

const InEvidenceTemplate = ({
  items,
  isEditMode,
  title,
  linkMore,
  show_block_bg = false,
}) => {
  return (
    <div className="in-evidence-template">
      <div className="grid">
        {items.map((item) => (
          <ConditionalLink
            condition={!isEditMode}
            className="item"
            to={flattenToAppURL(item['@id'])}
            key={item['@id']}
          >
            <div>
              {
                <div className="mb-05 cont">
                  <Image
                    src={
                      item.image
                        ? flattenToAppURL(
                            item.image?.scales?.preview?.download || '',
                          )
                        : bgImage
                    }
                    className={cx('img', { 'object-fit_cover': !item.image })}
                  />
                  <div className="other"></div>
                </div>
              }
              <div className="mb-1 description">{item.title}</div>
              <div className="flex align-center">
                <span className="mr-05 text">Scopri di più</span>
                <FontAwesomeIcon icon={faArrowCircleRight} />
              </div>
            </div>
          </ConditionalLink>
        ))}
      </div>
      {linkMore?.href && (
        <h4 className="flex justify-center linkmore">
          <ConditionalLink
            condition={!isEditMode}
            to={linkMore.href}
            className="mt-2"
          >
            {linkMore.title}
          </ConditionalLink>
        </h4>
      )}
    </div>
  );
};
export default InEvidenceTemplate;
