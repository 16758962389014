import React from 'react';
import { Breadcrumbs } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
const TitleStandard = ({ title }) => {
  const path = getBaseUrl((__CLIENT__ && window.location.pathname) || '');

  return (
    <>
      <div
        className="title-standard page-title padding-20"
        style={{ marginTop: '-30px' }}
      >
        <h1>{title}</h1>
      </div>
      <Breadcrumbs pathname={path} />
    </>
  );
};

export default TitleStandard;
