import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import RichText from '../RichText';
import EventLocationMap from './EventLocationMap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

/**
 * EventDescriptionSection view component class.
 * @function EventDescriptionSection
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const EventDescriptionSection = ({ description, location }) => {
  const token = useSelector((state) => state.userSession.token);
  const objectActions = useSelector((state) => state.actions.actions.object);
  const editPermission = find(objectActions, { id: 'edit' });

  /**
   * hasLocation is quite tricky. if we have token and edit permission we can
   * consider to have location also if it's private. This IS NOT plonish. we
   * don't check permission over the location. We assume we have only manger on
   * the site so if you have edit permission on the event, 99% sure you have
   * permission on the location.
   * Case are:
   * We have location
   *  it's private but I can see it
   *  It's private and I can't see it
   * We don't have location
   *  nothing to see
   */
  const private_location = location?.review_state === 'private';
  const hasLocation = location
    ? private_location && token && editPermission
      ? true
      : !private_location
      ? true
      : false
    : false;

  return (
    <Container className={'description-section'}>
      <Grid className={'centered'}>
        <Grid.Column
          mobile={16}
          tablet={10}
          computer={hasLocation ? 6 : 16}
          className="mt-1"
        >
          <RichText
            title={'Descrizione'}
            content={description}
            title_size={'h3'}
          />
        </Grid.Column>
        {hasLocation && (
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <EventLocationMap location={location} />
          </Grid.Column>
        )}
      </Grid>
    </Container>
  );
};

export default EventDescriptionSection;

EventDescriptionSection.propTypes = {
  description: PropTypes.string,
  location: PropTypes.shape({
    '@id': PropTypes.string,
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    review_state: PropTypes.string,
  }),
};
