import React, { useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';

const Body = ({ data }) => {
  const [check, setCheck] = useState(false);
  const toggle = () => setCheck(!check);
  return (
    <>
      <div className="newsletter">
        <form
          action="https://gmail.us2.list-manage.com/subscribe/post?u=5b17f7bfbb696e7dc1a50550b&amp;id=761051bbcb"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div className="pt-3 pb-2 white">
            <div className="flex justify-center text">
              Iscriviti alla nostra Newsletter
            </div>
            <div className="mt-1">
              <input type="text" className="rounded" name="EMAIL"></input>
            </div>
            <div className="mt-1 mb-1">
              <Checkbox
                label="Acconsento al trattamento dei dati personali per i fini e i modi descritti dalla privacy"
                onClick={toggle}
              />
            </div>
            <Button color="yellow" disabled={!check} type="submit">
              Invia
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Body;
