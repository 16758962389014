import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { SelectWidget } from '@plone/volto/components';
import { components } from 'react-select';
import IconPreviewWidget from './IconPreviewWidget';
import { Image } from 'semantic-ui-react';

const messages = defineMessages({
  icon: {
    id: 'icon',
    defaultMessage: 'Icona',
  },
  description: {
    id: 'iconDescription',
    defaultMessage:
      'Puoi selezionare un’icona fra quelle proposte nel menu a tendina',
  },
});

const IconWidget = ({ id, value, defaultOptions, onChange }) => {
  const intl = useIntl();
  const [selectValue, setSelectValue] = useState(value);

  const { Option } = components;
  const CustomSelectOption = (props) => {
    return (
      <Option {...props}>
        <span className="icon-container">
          <Image
            src={props.data.value !== 'no-value' ? props.data.value : null}
          />
        </span>
        <span className="label-container">{props.data.label}</span>
      </Option>
    );
  };

  return (
    <div className="select-icon-widget">
      {defaultOptions && defaultOptions.length > 0 && (
        <SelectWidget
          id="selectIcon"
          title={intl.formatMessage(messages.icon)}
          required={false}
          value={selectValue}
          intl={intl}
          onChange={(_id, value) => {
            setSelectValue(value);
            onChange(id, value);
          }}
          choices={defaultOptions}
          customOption={CustomSelectOption}
        />
      )}
      <IconPreviewWidget icon={selectValue}>
        {intl.formatMessage(messages.description)}
      </IconPreviewWidget>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IconWidget.propTypes = {
  onChange: PropTypes.func,
};

export default IconWidget;
