import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getContent, resetContent } from '@plone/volto/actions';
import cx from 'classnames';
import { CardCalendar } from './CardCalendar';
import { Link } from 'react-router-dom';
import altriAppuntamenti from '../../../assets/eventsImage/altri_appuntamenti.jpg';
import degustazione from '../../../assets/eventsImage/degustazione.jpg';
import incontri_spettacoli from '../../../assets/eventsImage/incontri_spettacoli.jpg';
import laboratori from '../../../assets/eventsImage/laboratori.jpg';
import mercati from '../../../assets/eventsImage/mercati.jpg';
import mostre from '../../../assets/eventsImage/mostre.jpg';
import per_bambini from '../../../assets/eventsImage/per_bambini.jpg';
import visite_guidate from '../../../assets/eventsImage/visite_guidate.jpg';

const ListingEvent = ({ event, fullobjects = false }) => {
  const key = `${event['@id']}_listing_event_${event.effective}`;
  const searchResults = useSelector((state) => state.content.subrequests);
  const url = flattenToAppURL(event['@id']);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!fullobjects) {
      dispatch(getContent(url, null, key, null, true));
      return () => dispatch(resetContent(key));
    }
  }, [dispatch, event, url, key, fullobjects]);

  let event_fo = fullobjects ? event : searchResults[key]?.data;

  const getDefaultImage = (type) => {
    switch (type) {
      case 'incontri-e-spettacoli':
        return incontri_spettacoli;
      case 'degustazioni-e-cene':
        return degustazione;
      case 'laboratori-e-show-cooking':
        return laboratori;
      case 'mercati':
        return mercati;
      case 'mostre':
        return mostre;
      case 'per-bambini':
        return per_bambini;
      case 'visite-guidate':
        return visite_guidate;
      case 'altri-appuntamenti':
        return altriAppuntamenti;
      default:
        return undefined;
    }
  };
  return event_fo ? (
    <div
      className={cx('col-item', {
        'card-img': event_fo.image,
      })}
    >
      <Link to={flattenToAppURL(event_fo['@id'])}>
        <div className={'overlay'}>
          <>
            <img
              className="item-image object-fit_cover"
              src={
                event_fo.image
                  ? flattenToAppURL(
                      event_fo.image?.scales?.preview?.download || '',
                    )
                  : getDefaultImage(event_fo.tipologia_evento[0]?.token)
              }
              alt={event_fo.title}
              wrapped
            />
            <div className={''}>
              {event_fo.tipologia_evento &&
                event_fo.tipologia_evento.length > 0 && (
                  <div className={'top-right'}>
                    {event_fo.tipologia_evento?.map((argument, index) => (
                      <div
                        color="primary"
                        disabled={false}
                        simple
                        tag="div"
                        className="event-category"
                        key={index}
                      >
                        <div tag="span">{argument.title}</div>
                      </div>
                    ))}
                  </div>
                )}
              <div className="bottom-left">
                <CardCalendar
                  start={event_fo.start}
                  end={event_fo.end}
                  recurrence={event_fo.recurrence}
                />
              </div>
            </div>
          </>
        </div>
        <div className={'card-title'}>{event_fo.title || event_fo.id}</div>
        <div className="other"></div>
      </Link>
    </div>
  ) : null;
};

ListingEvent.propTypes = {
  event: PropTypes.any,
};

const ListingEvents = ({ events, svg, color, fullobjects = false }) => {
  return (
    <div className={'events-listing-template'}>
      <Grid.Row className="flex mt-2 flex-wrap mb-2">
        {events.map((event, index) => (
          <ListingEvent
            event={event}
            key={`${event['@id']}_${event.effective}`}
            fullobjects={fullobjects}
          />
        ))}
      </Grid.Row>
    </div>
  );
};

ListingEvents.propTypes = {
  events: PropTypes.array,
};

export default ListingEvents;
