import React from 'react';
import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import SponsorsSidebar from './SponsorsSidebar';
import Body from './Body';
import BodyWrapper from './BodyWrapper';
import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';

class Edit extends SubblocksEdit {
  render() {
    return (
      <>
        <div className="block sponsors">
          <SubblocksWrapper node={this.node}>
            <BodyWrapper>
              {this.state.subblocks.map((subblock, subindex) => (
                <Body
                  data={subblock}
                  index={subindex}
                  key={subblock.id}
                  isEditMode={true}
                  selected={this.isSubblockSelected(subindex)}
                  {...this.subblockProps}
                  intl={this.props.intl}
                  openObjectBrowser={this.props.openObjectBrowser}
                  onChangeFocus={this.onSubblockChangeFocus}
                />
              ))}
              {this.props.selected && this.renderAddBlockButton()}
            </BodyWrapper>
          </SubblocksWrapper>
        </div>
        <SidebarPortal selected={this.props.selected || false}>
          <SponsorsSidebar
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
            onChangeSubBlock={this.onChangeSubblocks}
            selected={this.state.subIndexSelected}
            setSelected={this.onSubblockChangeFocus}
          />
        </SidebarPortal>
      </>
    );
  }
}

Edit.propTypes = {
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pathname: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default React.memo(withDNDContext(Edit));
