import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';

const View = ({ data, id }) => {
  return <Body data={data} />;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
};

export default View;
