import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getContent, resetContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { OSMMap } from 'volto-venue';
import PropTypes from 'prop-types';

/**
 * EventLocationMap view component class.
 * @function EventLocationMap
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const EventLocationMap = ({ location }) => {
  if (!location) return null;
  const key = `location-${location?.['@id']}`;
  const url = flattenToAppURL(location['@id']);
  const locationContent = useSelector((state) => state.content.subrequests);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!location) {
      dispatch(getContent(url, null, key, null, true));
      return () => dispatch(resetContent(key));
    }
  }, [dispatch, location, url, key]);

  if (!location) return null;

  let location_fo = locationContent[key]?.data;

  return location_fo ? (
    <div className="event-map">
      {__CLIENT__ &&
      location_fo?.geolocation?.latitude &&
      location_fo?.geolocation?.longitude ? (
        <>
          <OSMMap
            markers={[
              {
                latitude: location_fo?.geolocation?.latitude || 0,
                longitude: location_fo?.geolocation?.longitude || 0,
              },
            ]}
          />
        </>
      ) : null}
    </div>
  ) : null;
};

export default EventLocationMap;

EventLocationMap.propTypes = {
  location: PropTypes.shape({
    '@id': PropTypes.string,
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    review_state: PropTypes.string,
  }),
};
