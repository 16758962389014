import React from 'react';

const BodyWrapper = ({ children }) => {
  return (
    <div className="px-1">
      <div className="grid justify-items-center align-center">{children}</div>
    </div>
  );
};
export default BodyWrapper;
