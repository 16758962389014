import { endsWith, find, keys } from 'lodash';

const hasBlocksDataExtended = (content, exclude = ['title', 'description']) => {
  const foundBlocks = find(
    keys(content),
    (key) => key !== 'volto.blocks' && endsWith(key, 'blocks'),
  );

  if (!foundBlocks) return !!foundBlocks;
  else {
    const blocks = content[foundBlocks];

    const hasBlocksToRender = Object.keys(blocks).filter(
      (e) => !exclude.includes(blocks[e]['@type']),
    );

    return hasBlocksToRender.length > 0;
  }
};

export default hasBlocksDataExtended;
