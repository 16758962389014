import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useIntl } from 'react-intl';
import moment from 'moment';
import 'moment/min/locales';
import { Grid, Container } from 'semantic-ui-react';
import { CardCalendar } from '../../View/Commons/CardCalendar';

const EventsListingTemplate = ({ items, isEditMode, linkMore }) => {
  const intl = useIntl();
  moment.locale(intl.locale);

  return (
    <div className="events-listing-template">
      <div className="items">
        <Container className="px-4">
          <Grid.Row>
            <Grid.Column>
              <h2 className="mb-4">Ti potrebbe interessare anche</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="flex justify-between mt-2 flex-wrap">
            {items.slice(0, 5).map((item, index) => {
              return (
                <div
                  className={cx('col-item', {
                    'card-img': item.image,
                  })}
                >
                  <div className={'overlay'}>
                    {item.image && (
                      <>
                        <img
                          className="item-image object-fit_cover"
                          src={flattenToAppURL(
                            item.image?.scales?.preview?.download || '',
                          )}
                          alt={item.title}
                          wrapped
                        />
                        <div className={''}>
                          <div className={'top-right'}>
                            {item.tipologia_evento?.map((argument, index) => (
                              <div
                                color="primary"
                                disabled={false}
                                simple
                                tag="div"
                                className="mr-2"
                                key={index}
                              >
                                <div tag="span">{argument.title}</div>
                              </div>
                            ))}
                          </div>
                          <div className="bottom-left">
                            <CardCalendar start={item.start} end={item.end} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={'card-title'}>
                    <ConditionalLink
                      condition={!isEditMode}
                      to={flattenToAppURL(item['@id'])}
                    >
                      {item.title || item.id}
                    </ConditionalLink>
                  </div>
                  <div className="other"></div>
                </div>
              );
            })}
          </Grid.Row>
          {linkMore?.href && (
            <div className="link-more">
              <ConditionalLink condition={!isEditMode} to={linkMore.href}>
                {linkMore.title}
              </ConditionalLink>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

EventsListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default EventsListingTemplate;
