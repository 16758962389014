import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { TextWidget } from '@plone/volto/components';

const messages = defineMessages({
  News: {
    id: 'News',
    defaultMessage: 'News',
  },
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
});

const QuotedTextSidebar = ({ block, data, onChangeBlock, required }) => {
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="NewsHome"
            defaultMessage="News con immagine in primo piano"
          />
        </h2>
      </header>

      <Segment className="form">
        <TextWidget
          id="description"
          title={intl.formatMessage(messages.description)}
          required={false}
          value={data.description}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

QuotedTextSidebar.propTypes = {
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default QuotedTextSidebar;
