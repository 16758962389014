import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import hasBlocksDataExtended from './utils';
import RenderBlocks from './RenderBlocks';

const richTextHasContent = (content, exclude = ['title', 'description']) => {
  if (hasBlocksDataExtended(content, exclude)) {
    //ReactDOMServer.renderToStaticMarkup(RenderBlocks({ content: content })),
    return RenderBlocks({ content: content }) != null;
  } else {
    const textToDisplay = content?.replace(/(<([^>]+)>)/g, '') ?? '';
    return textToDisplay.length > 0 ? true : false;
  }
};

/**
 * RichTextRender view component class.
 * @function RichTextRender
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const RichTextRender = ({ content, add_class, serif = true }) => {
  let hasContent = richTextHasContent(content);
  return hasContent ? (
    hasBlocksDataExtended(content) ? (
      <div
        className={cx(`richtext-blocks ${add_class ?? ''}`, {
          'text-serif': serif,
        })}
      >
        <RenderBlocks content={content} />
      </div>
    ) : (
      <div
        className={cx(add_class, { 'text-serif': serif })}
        dangerouslySetInnerHTML={{ __html: flattenHTMLToAppURL(content) }}
      />
    )
  ) : null;
};

export { RichTextRender, richTextHasContent };

RichTextRender.propTypes = {
  content: PropTypes.string,
  add_class: PropTypes.string,
};
