import React, { useEffect } from 'react';
import TitleStandard from '../Commons/TitleStandard';
import { getContent, resetContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import RestaurantCardWithRibbon from './Commons/Restaurants/RestaurantCardWithRibbon';
import ListingEvents from './Commons/ListingEvents';
import RestaurantMap from './Commons/Restaurants/RestaurantMap';
import RestaurantInfoSection from './Commons/Restaurants/RestaurantInfoSection';
import TriangoloGiallo from '../../assets/triangolo_giallo_osteria.svg';
import TriangoloRosso from '../../assets/triangolo_rosso_agriturismo.svg';
import SVGRicette from '../../assets/customIcons/icona_ricette.png';
import SVGMenu from '../../assets/customIcons/icona_menu.png';
import { Grid, Container } from 'semantic-ui-react';
import RichText from './Commons/RichText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import CommonImage from './Commons/Image';

const messages = defineMessages({
  menu_list1: {
    id: 'menu_list1',
    defaultMessage: 'I menu di',
  },
  menu_list2: {
    id: 'menu_list2',
    defaultMessage: 'per Baccanale',
  },
  menu_list_all: {
    id: 'menu_list_all',
    defaultMessage: 'Vedi tutti i menu di',
  },
  recipes_list1: {
    id: 'recipes_list1',
    defaultMessage: 'Le ricette di',
  },
  recipes_list2: {
    id: 'recipes_list2',
    defaultMessage: 'per Baccanale',
  },
  recipes_list_all: {
    id: 'recipes_list_all',
    defaultMessage: 'Vedi tutte le ricette di',
  },
  events: {
    id: 'events',
    defaultMessage: 'Eventi in programma per',
  },
  website: {
    id: 'website',
    defaultMessage: 'Sito web',
  },
  facebook: {
    id: 'facebook',
    defaultMessage: 'Facebook',
  },
  instagram: {
    id: 'instagram',
    defaultMessage: 'Instagram',
  },
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
});

const RestaurantView = ({ content }) => {
  const intl = useIntl();
  const searchResults = useSelector((state) => {
    // ritorna un oggetto del tipo
    // subrequests:
    // http://localhost:3000/api/ristoranti/ristorante-1/cartella-menu: {data: null, loaded: false, loading: true, error: null}
    // http://localhost:3000/api/ristoranti/ristorante-1/cartella-ricette: {loading: false, loaded: true, error: null, data: {…}}
    const requests = state.content?.subrequests;
    const newRquests = Object.keys(requests).map((key) => {
      const newKey = key.split('/').pop();
      return { [newKey]: requests[key] };
    });
    return newRquests.reduce((a, b) => Object.assign({}, a, b), []);
  });
  const dispatch = useDispatch();
  useEffect(() => {
    const cartella_menu = content.items.find(
      (c) => c['@type'] === 'Cartella Menu',
    );
    const cartella_ricette = content.items.find(
      (c) => c['@type'] === 'Cartella Ricette',
    );

    content &&
      dispatch(
        getContent(
          flattenToAppURL(cartella_menu['@id']),
          null,
          cartella_menu['@id'],
          null,
          true,
        ),
      );
    content &&
      dispatch(
        getContent(
          flattenToAppURL(cartella_ricette['@id']),
          null,
          cartella_ricette['@id'],
          null,
          true,
        ),
      );
    return () => {
      content && dispatch(resetContent(cartella_menu['@id']));
      content && dispatch(resetContent(cartella_ricette['@id']));
    };
  }, [dispatch, content]);

  const menus = searchResults.hasOwnProperty('cartella-menu')
    ? searchResults['cartella-menu']?.data?.items
    : searchResults['menu']?.data?.items;
  const ricette = searchResults.hasOwnProperty('cartella-ricette')
    ? searchResults['cartella-ricette']?.data?.items
    : searchResults['ricette']?.data?.items;
  return (
    <>
      <TitleStandard title={content.title} />
      <div className="restaurant ui container">
        {/* Dati del ristorante */}
        <RestaurantInfoSection content={content} />

        {/* Menù agriturismo */}
        <Container>
          {menus?.length > 0 ? (
            <div className="mb-3 mt-3">
              <img src={SVGMenu} className="centered mt-2" alt="" />
              <div className="center mt-05">
                <h2 className="primary-color">
                  {`${intl.formatMessage(messages.menu_list1)} ${
                    content.title
                  } ${intl.formatMessage(messages.menu_list2)}`}
                </h2>
              </div>
              <div className="mt-1 super-padding">
                {menus?.slice(0, 2)?.map((sr) => (
                  <RestaurantCardWithRibbon
                    title={sr.title}
                    linkID={sr['@id']}
                    description={sr.description}
                    svg={TriangoloRosso}
                    color={'primary-color'}
                    icon_color={'#db564f'}
                    year={sr.anno}
                  />
                ))}
              </div>
              <div className={'mt-1 super-padding'}>
                <Link
                  to={flattenToAppURL(
                    `${content['@id']}/${
                      searchResults.hasOwnProperty('cartella-menu')
                        ? 'cartella-menu'
                        : 'menu'
                    }`,
                  )}
                  style={{ color: 'inherit' }}
                >
                  <span className={'mr-05'}>
                    {`${intl.formatMessage(messages.menu_list_all)} ${
                      content.title
                    }`}
                  </span>
                  <FontAwesomeIcon icon={faArrowCircleRight} color="#db564f" />
                </Link>
              </div>
            </div>
          ) : null}

          {ricette?.length > 0 ? (
            <Grid className="mb-3">
              <Grid.Row className={'description-row pt-0'}>
                <Container className="recipes-section">
                  <img src={SVGRicette} className="centered" alt="" />
                  <div className="center mt-05">
                    <h2 className="senape">
                      {`${intl.formatMessage(messages.recipes_list1)} ${
                        content.title
                      } ${intl.formatMessage(messages.recipes_list2)}`}
                    </h2>
                  </div>
                  <div className="mt-1 super-padding">
                    {ricette?.slice(0, 2)?.map((sr) => (
                      <RestaurantCardWithRibbon
                        title={sr.title}
                        linkID={sr['@id']}
                        description={sr.description}
                        svg={TriangoloGiallo}
                        color={'senape'}
                        icon_color={'#dbcb4f'}
                        year={sr.anno}
                      />
                    ))}
                  </div>
                  <div className={'mt-1 super-padding'}>
                    <Link
                      to={flattenToAppURL(
                        `${content['@id']}/${
                          searchResults.hasOwnProperty('cartella-ricette')
                            ? 'cartella-ricette'
                            : 'ricette'
                        }`,
                      )}
                      style={{ color: 'inherit' }}
                    >
                      <span className={'mr-05'}>
                        {`${intl.formatMessage(messages.recipes_list_all)} ${
                          content.title
                        }`}
                      </span>
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#dbcb4f"
                      />
                    </Link>
                  </div>
                </Container>
              </Grid.Row>
            </Grid>
          ) : null}
        </Container>

        {/* Eventi collegati al ristorante */}
        {content.events?.length > 0 && (
          <div className="mt-3 mb-5">
            <h3>{`${intl.formatMessage(messages.events)} ${content.title}`}</h3>
            <ListingEvents events={content.events} />
          </div>
        )}

        {/* Info sul ristorante */}
        <div>
          <Grid>
            <Grid.Row className={'description-row'}>
              <Container className={'description-section'}>
                <Grid>
                  <Grid.Column computer={6} mobile={12} tablet={12}>
                    <div className="mt-05">
                      {content.descrizione_estesa?.data?.replace(
                        /(<([^>]+)>)/g,
                        '',
                      ) && (
                        <RichText
                          content={content.descrizione_estesa}
                          title_size={'h3'}
                        />
                      )}
                      <div className="mt-2 mb-05">
                        {content.sito_web && (
                          <div tag="h4">
                            <a href={content.sito_web}>
                              {intl.formatMessage(messages.website)}
                            </a>
                          </div>
                        )}
                        {content.facebook && (
                          <div tag="h4">
                            <a href={content.facebook}>
                              {intl.formatMessage(messages.facebook)}
                            </a>
                          </div>
                        )}
                        {content.instagram && (
                          <div tag="h4">
                            <a href={content.instagram}>
                              {intl.formatMessage(messages.instagram)}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    computer={6}
                    mobile={12}
                    tablet={12}
                    className="col-img-plonly"
                  >
                    <div className="description-image-col">
                      <CommonImage
                        image_class={'stretch-image object-fit_cover'}
                        image={content.image?.download}
                        caption={null}
                      />
                    </div>
                  </Grid.Column>
                </Grid>
              </Container>
            </Grid.Row>
          </Grid>
        </div>
        {/* Mappa del ristorante */}

        <div className="fullwidth-map">
          <RestaurantMap geolocation={content.geolocation} />
        </div>
      </div>
    </>
  );
};

export default RestaurantView;
