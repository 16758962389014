import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';

const RestaurantCardWithRibbon = ({
  title,
  linkID,
  svg,
  color,
  icon_color,
  year,
}) => {
  return (
    <Link to={flattenToAppURL(linkID)}>
      <div>
        <div className="restaurant-ribbon-card card mt-1">
          <p className={'card-header'}>
            <img
              src={svg}
              alt={'colored-corner'}
              className={'colored-corner'}
            />
            <span className="description">{`Menu per baccanale ${year}`}</span>
          </p>

          <div className={'card-content'}>
            <div className={color}>{title}</div>
          </div>

          <div className={'mt-05'}>
            <FontAwesomeIcon icon={faArrowCircleRight} color={icon_color} />
          </div>
          <div className="other"></div>
        </div>
      </div>
    </Link>
  );
};
export default RestaurantCardWithRibbon;
