import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';

const PrintPDF = ({ item }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );

  const userLoggedSt = useSelector((state) => state.users);
  useEffect(() => {
    if (!userLoggedSt?.get?.loading && userId) {
      dispatch(getUser(userId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return userLoggedSt?.user?.roles?.includes('Manager') ? (
    <div className={`printPDF ${!userId ? 'hidden' : ''}`}>
      <div>
        <h4 className="year">{`${item['@type']} per baccanale ${item.anno}`}</h4>
      </div>
      <div>
        <button className="printButton" onClick={() => window.print()}>
          Esporta PDF
        </button>
      </div>
    </div>
  ) : null;
};

export default PrintPDF;
