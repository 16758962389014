import React from 'react';
import TitleStandard from '../Commons/TitleStandard';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image, Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  scarica: {
    id: 'scarica',
    defaultMessage: 'Scarica',
  },
});

const FileView = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      <TitleStandard title={content.title} />
      <div className="ui container file-view">
        <div className="super-padding mt-3">
          <div>
            <Image
              src={flattenToAppURL(
                content?.image?.scales?.preview?.download || '',
              )}
              centered
              className="img"
            />
          </div>
          <div className="flex justify-center mb-2">
            Clicca il bottone per scaricare il file
          </div>
          {content.file && (
            <div className="flex justify-center mb-2">
              <Button
                size="large"
                className="down-button"
                onClick={() => {
                  window.location = content.file?.download;
                }}
              >
                {intl.formatMessage(messages.scarica)}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FileView;
