/**
 * LuogoView view component.
 * @module components/theme/View/PageView
 */

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Segment, Grid } from 'semantic-ui-react';

import EventInfoSection from './Commons/Events/EventInfoSection';
import EventDescriptionSection from './Commons/Events/EventDescriptionSection';
import ListingEvents from './Commons/ListingEvents';
import TitleStandard from '../Commons/TitleStandard';

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  inThisSection: {
    id: 'In this section',
    defaultMessage: 'In questa sezione',
  },
  events: {
    id: 'events',
    defaultMessage: 'Ti potrebbe interessare anche',
  },
});

const EventView = ({ content }) => {
  const intl = useIntl();
  return (
    <>
      <TitleStandard title={content.title} />
      <div id="event">
        <div className="ui container">
          <Segment basic>
            <Grid>
              <Grid.Row className={''}>
                <EventInfoSection content={content} />
              </Grid.Row>
              <Grid.Row className={'description-row'}>
                <EventDescriptionSection
                  description={content.descrizione_estesa}
                  location={content.location[0]}
                />
              </Grid.Row>
              <Grid.Row>
                <div className="mt-1p5 mb-5">
                  <h3>{`${intl.formatMessage(messages.events)}
                  ...`}</h3>
                  <ListingEvents
                    events={content.events
                      ?.sort((a, b) => new Date(a.start) - new Date(b.start))
                      ?.slice(0, 3)}
                  />
                </div>
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
      </div>
    </>
  );
};

export default EventView;
